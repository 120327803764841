






import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {},
  setup(props, { emit }) {
    const { deleteShareholder } = useActions(['deleteShareholder']);
    const deleting = ref<boolean>(false);

    return {
      deleting,
      deleteShareholder: async (shareholderId: string) => {
        deleting.value = true;

        const id = await deleteShareholder(shareholderId);

        if (id) {
          deleting.value = false;
          emit('deleted', id);
        }
      },
    };
  },
});
