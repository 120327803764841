




























import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Company, CompanyFragment, Person, PersonFragment, PlannedMeasure, PlannedMeasureFragment } from '@/graphql';
import ResourceIcon from '@/components/Icons/ResourceIcon.vue';

export type OnSearchCallback = (searchQuery: string) => Promise<void>;

export default defineComponent({
  components: { ResourceIcon },
  props: {
    onSearch: {
      type: Function as PropType<OnSearchCallback>,
      required: true,
    },
    results: {
      type: Array as PropType<(PersonFragment | CompanyFragment | PlannedMeasureFragment)[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { root }) {
    const items = ref(props.results);
    const search = ref<string>('');
    const selected = ref();

    watch(
      () => props.results,
      (results) => {
        return (items.value = results);
      },
    );

    watch(search, (value: string) => {
      if (value) {
        props.onSearch(value);
      } else {
        items.value = [];
      }
    });

    return {
      search,
      selected,
      items,
      onSelect: (item: Person | Company | PlannedMeasure) => {
        switch (item.__typename) {
          case 'Company':
            root.$router.push(`/companies/${item.id}`);
            break;
          case 'Person':
            root.$router.push(`/persons/${item.id}`);
            break;
          case 'PlannedMeasure':
            root.$router.push(`/planned-measures/${item.id}`);
            break;
        }
      },
    };
  },
});
