












import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const dashboardData = ref<object | null>(null);

    const { loadDashboardData } = useActions(['loadDashboardData']);

    onMounted(async () => {
      loading.value = true;
      dashboardData.value = await loadDashboardData();
      loading.value = false;

      if (dashboardData.value) {
        emit('loaded');
      }
    });

    return {
      loading,
      dashboardData: computed(() => (dashboardData.value ? dashboardData.value : {})),
    };
  },
});
