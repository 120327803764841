<template>
  <v-container class="fill-height">
    <v-row class="mb-10" justify="center" no-gutters>
      <v-col cols="auto" class="text-center">
        <p class="text-h6 text-secondary">No access.</p>
        <p class="text-secondary">
          You do not seem to have access to SMD. Please check that you are a member of the SMD project.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({});
</script>

<style scoped></style>
