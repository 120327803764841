






import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { DocumentType } from '@/graphql';

export type UploadDocumentCallback = (companyId: string, file: File, documentType: DocumentType) => Promise<void>;

export default defineComponent({
  setup(props, { emit }) {
    const uploading = ref<boolean>(false);
    const { uploadDocument } = useActions(['uploadDocument']);

    const upload: UploadDocumentCallback = async (companyId, file, documentType) => {
      uploading.value = true;

      const uploaded: boolean | null = await uploadDocument({
        companyId,
        file,
        documentType,
      });

      uploading.value = false;

      if (uploaded) {
        emit('uploaded');
      }
    };

    return {
      uploading,
      upload,
    };
  },
});
