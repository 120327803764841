























import { defineComponent, PropType } from '@vue/composition-api';
import { Contact } from '@/graphql';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<Contact[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
