






import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { AuthorityFragment, CompanyLegalType, RepresentationType, Role, UpsertAuthorityInput } from '@/graphql';
import { allowedRoles } from '@/constants';

export default defineComponent({
  props: {
    companyId: {
      type: String as PropType<string>,
      required: true,
    },
    legalType: {
      type: String as PropType<CompanyLegalType>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { upsertAuthority } = useActions(['upsertAuthority']);
    const upserting = ref<boolean>(false);

    return {
      upserting,
      upsert: async (model: UpsertAuthorityInput) => {
        upserting.value = true;

        let role: Role | undefined = model.role ?? undefined;

        if (!role && !model.id) {
          role = allowedRoles[props.legalType][0];
        }

        const data: AuthorityFragment | null = await upsertAuthority({
          id: model.id ?? undefined,
          personId: model.personId ?? undefined,
          companyId: model.companyId ?? undefined,
          role,
          representationType: model.representationType ?? RepresentationType.Solely,
          since: model.since ?? undefined,
        } as UpsertAuthorityInput);

        if (data) {
          upserting.value = false;
          emit('upserted', data);
        }
      },
    };
  },
});
