
































































































import { defineComponent, reactive, ref } from '@vue/composition-api';
import Layout from '@/components/Layout.vue';
import PlannedMeasureListProvider from '@/providers/PlannedMeasureListProvider.vue';
import { PlannedMeasure } from '@/graphql';
import PlannedMeasureProvider from '@/providers/PlannedMeasureProvider.vue';
import PlannedMeasureDrawer from '@/components/Drawers/PlannedMeasureDrawer.vue';
import RouteAware from '@/components/Route/RouteAware.vue';
import PlannedMeasureSourceChip from '@/components/Chips/PlannedMeasureSourceChip.vue';
import Facet from '@/components/Facet/Facet.vue';
import { plannedMeasureSources } from '@/constants';
import PlannedMeasureUpsertProvider from '@/providers/PlannedMeasureUpsertProvider.vue';
import { useGetters } from 'vuex-composition-helpers';

export default defineComponent({
  components: {
    PlannedMeasureUpsertProvider,
    Facet,
    PlannedMeasureSourceChip,
    RouteAware,
    PlannedMeasureDrawer,
    PlannedMeasureProvider,
    PlannedMeasureListProvider,
    Layout,
  },
  setup(props, { root }) {
    const search = ref('');
    const { isManager, isAdmin } = useGetters(['isManager', 'isAdmin']);

    const facets = reactive<{ [key: string]: unknown[] }>({
      referenceSource: [],
    });

    const filter = (column: string): Function => {
      return (value?: string | null) => {
        return facets[column].length ? facets[column].includes(value) : true;
      };
    };

    return {
      referenceSources: Object.entries(plannedMeasureSources).map(([k, v]) => ({ text: v, value: k })),
      userHasEditAccess: isManager.value || isAdmin.value,
      facets,
      search,
      filter,
      onSelect: (plannedMeasure: PlannedMeasure | null) => {
        root.$router.replace({
          name: 'planned-measures',
          params: { id: plannedMeasure?.id || '' },
        });
      },
      onClose: () => {
        root.$router.replace({
          name: 'planned-measures',
        });
      },
    };
  },
});
