import { ActionContext, Module } from 'vuex';
import {
  DeleteContactDocument,
  DeleteContactMutation,
  DeleteContactMutationVariables,
  ListContactParams,
  ListContactsDocument,
  ListContactsQuery,
  ListContactsQueryVariables,
  UpsertContactDocument,
  UpsertContactInput,
  UpsertContactMutation,
  UpsertContactMutationVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type ContactsState = {};

type Context = ActionContext<ContactsState, RootState>;

export default {
  actions: {
    async loadContacts({ commit }: Context, listParams: ListContactParams = {}) {
      const { data } = await apolloClient.query<ListContactsQuery, ListContactsQueryVariables>({
        query: ListContactsDocument,
        variables: { listParams },
      });

      return data.listContacts || [];
    },

    async upsertContact({ commit }: Context, upsertContactInput: UpsertContactInput) {
      const { data } = await apolloClient.mutate<UpsertContactMutation, UpsertContactMutationVariables>({
        mutation: UpsertContactDocument,
        variables: { upsertContactInput },
      });

      return data?.upsertContact.data ?? null;
    },

    async deleteContact({ commit }: Context, contactId: string) {
      return apolloClient.mutate<DeleteContactMutation, DeleteContactMutationVariables>({
        mutation: DeleteContactDocument,
        variables: { contactId },
      });
    },
  },
} as Module<ContactsState, RootState>;
