

























import { computed, defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    fields: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: false,
      default: () => null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const collapsed = ref<boolean>(Object.keys(props.fields).length > 8);

    watch(
      () => props.item,
      () => {
        collapsed.value = Object.keys(props.fields).length > 8;
      },
    );

    return {
      visibleFields: computed(() => {
        const entries = Object.entries(props.fields);

        if (collapsed.value) {
          entries.splice(6);
        }

        return Object.fromEntries(entries);
      }),
      expand: () => {
        collapsed.value = false;
      },
      collapsed,
      skeletonWidth: (label: string) => {
        const percentage: number = Math.min(Math.max(label.length * 5, 40), 100);

        return `${percentage}%`;
      },
    };
  },
});
