





import { computed, defineComponent, PropType } from '@vue/composition-api';
import { RepresentationType } from '@/graphql';
import { representationTypes } from '@/constants';

export default defineComponent({
  props: {
    representationType: {
      type: String as PropType<RepresentationType>,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const representationTypeProps: { [key: string]: object } = Object.fromEntries(
      Object.entries(representationTypes).map(([key, value]) => [
        key,
        {
          color: 'blue lighten-1',
          textColor: 'white',
          label: value,
        },
      ]),
    );

    return {
      state: computed(() => {
        return (
          representationTypeProps[props.representationType] || {
            color: 'grey',
            textColor: 'white',
            label: props.representationType || 'Unbekannt',
          }
        );
      }),
    };
  },
});
