var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","right":"","temporary":"","hide-overlay":"","stateless":"","width":"600","value":_vm.open},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('Toolbar',{attrs:{"title":_vm.company ? _vm.company.name : ''}},[_c('Tooltip',{attrs:{"text":"Schließen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onClose}},on),[_c('v-icon',[_vm._v("mdi-arrow-collapse-right")])],1)]}}])})],1)]},proxy:true}])},[(!_vm.loading && _vm.isCompanyMerged)?_c('v-alert',{attrs:{"dense":"","type":"info","rounded":"0"}},[_vm._v(" Diese Firma ist bereits verschmolzen und kann daher nicht mehr bearbeitet werden. ")]):_vm._e(),(!_vm.userHasEditAccess && !_vm.isCompanyMerged)?_c('v-alert',{attrs:{"dense":"","text":"","type":"info","rounded":"0"}},[_vm._v(" Sie können diese Firma nicht bearbeiten, da Sie nur Lesezugriff haben. ")]):_vm._e(),(_vm.companyId)?_c('CompanyUpsertProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertCompany = ref.upsertCompany;
return [_c('CompanyForm',{staticClass:"ma-4",attrs:{"company":_vm.company,"on-submit":upsertCompany,"disabled":upserting,"editable":!_vm.isCompanyMerged && _vm.userHasEditAccess,"has-debit-agreement":_vm.hasCompanyDebitAgreement,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('FieldList',{attrs:{"loading":_vm.loading,"item":_vm.company,"fields":{
            name: 'Name',
            legalType: 'Geschäftsform',
            foundedAt: 'Gründungsdatum',
            capital: 'Stammkapital',
            legalStatus: 'Allgemeiner Status',
            status: 'SPV Status',
            formerName: 'Ehemalige Firmierung',
            usage: 'Gesellschaftstyp',
            purpose: 'Unternehmensgegenstand',
            address1: 'Adresszeile 1',
            address2: 'Adresszeile 2',
            city: 'Ort',
            state: 'Bundesland',
            zipCode: 'PLZ',
            country: 'Land',
            lei: 'LEI',
            leiValidUntil: 'LEI gültig bis',
            taxationAuthority: 'Finanzamt',
            taxId: 'Steuernummer',
            FsakId: 'Freistellungsaufträge-Kontrollverfahren',
            vatNumber: 'USt-IdNr.',
            ihkNumber: 'Gewerbenummer',
            creditorId: 'Gläubiger ID',
            wkn: 'WKN',
            isin: 'ISIN',
            bbankNumber: 'Bundesbanknummer',
            paymentId: 'Payment ID',
            mangoPayUserId: 'Mango Payment User ID',
            registrationNumber: 'Register-Nr.',
            registrationCourt: 'Registergericht',
            registeredAt: 'Eintragungsdatum',
            createdAt: 'Erstellt am',
            updatedAt: 'Geändert am',
          }},scopedSlots:_vm._u([{key:"legalType",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('CompanyLegalTypeChip',{attrs:{"legal-type":item.legalType || '',"small":""}})],1)]}},{key:"legalStatus",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('CompanyLegalStatusChip',{attrs:{"legal-status":item.legalStatus,"small":""}})],1)]}},{key:"status",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('CompanyStatusChip',{attrs:{"status":item.status,"small":""}})],1)]}},{key:"usage",fn:function(ref){
          var item = ref.item;
return [_c('span',[_c('CompanyUsageChip',{attrs:{"usage":item.usage,"small":""}})],1)]}},{key:"foundedAt",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.foundedAt)))])]}},{key:"leiValidUntil",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.leiValidUntil)))])]}},{key:"registeredAt",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.registeredAt)))])]}},{key:"createdAt",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.createdAt)))])]}},{key:"updatedAt",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(item.updatedAt)))])]}}],null,true)})]},proxy:true}],null,true)})]}}],null,false,2638543519)}):_vm._e(),(_vm.companyId && _vm.company)?_c('AuthorityListProvider',{staticClass:"ma-4",attrs:{"company-id":_vm.companyId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var authorities = ref.authorities;
          var loading = ref.loading;
return [_c('AuthoritiesCard',{attrs:{"authorities":authorities,"company-id":_vm.companyId,"loading":loading,"legal-type":_vm.company.legalType,"editable":!_vm.isCompanyMerged && _vm.userHasEditAccess}})]}}],null,false,2468644183)}):_vm._e(),(_vm.companyId && _vm.userHasAdminAccess)?_c('ShareholderListProvider',{attrs:{"owned-id":_vm.companyId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var shareholders = ref.shareholders;
          var holdings = ref.holdings;
          var loading = ref.loading;
return [_c('ShareholdersCard',{attrs:{"company-id":_vm.companyId,"shareholders":shareholders,"holdings":holdings,"loading":loading,"editable":!_vm.isCompanyMerged && _vm.userHasAdminAccess}})]}}],null,false,790568592)}):_vm._e(),(_vm.companyId)?_c('BankAccountListProvider',{staticClass:"ma-4",attrs:{"company-id":_vm.companyId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var bankAccounts = ref.bankAccounts;
          var loading = ref.loading;
return [_c('BankAccountsCard',{attrs:{"company-id":_vm.companyId,"loading":loading,"bank-accounts":bankAccounts,"editable":!_vm.isCompanyMerged && _vm.userHasEditAccess}})]}}],null,false,861521992)}):_vm._e(),(_vm.companyId)?_c('DocumentListProvider',{staticClass:"ma-4",attrs:{"company-id":_vm.companyId},on:{"loaded":_vm.onDocumentsLoaded},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var documents = ref.documents;
          var loading = ref.loading;
return [_c('DocumentsCard',{attrs:{"company-id":_vm.companyId,"loading":loading,"documents":documents,"editable":_vm.userHasEditAccess}})]}}],null,false,3680163525)}):_vm._e(),(_vm.companyId)?_c('PlannedMeasureListProvider',{staticClass:"ma-4",attrs:{"company-id":_vm.companyId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var plannedMeasures = ref.plannedMeasures;
          var loading = ref.loading;
return [_c('v-card',[_c('Toolbar',{attrs:{"dense":"","loading":loading,"title":"Geplante Maßnahmen"}}),_c('PlannedMeasureList',{attrs:{"loading":loading,"items":plannedMeasures,"linked":""}})],1)]}}],null,false,3424349780)}):_vm._e(),(_vm.companyId)?_c('CompanyListProvider',{staticClass:"ma-4",attrs:{"merged-into-id":_vm.companyId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var companies = ref.companies;
          var loading = ref.loading;
return [_c('v-card',[_c('Toolbar',{attrs:{"dense":"","loading":loading,"title":"Verschmolzene Gesellschaften"}}),_c('CompanyList',{attrs:{"loading":loading,"items":companies,"linked":""}})],1)]}}],null,false,863367122)}):_vm._e(),(_vm.companyId && _vm.company)?[_c('v-divider'),(_vm.userHasAdminAccess)?_c('v-card-text',[_c('CompanyDeleteProvider',{on:{"deleted":_vm.onClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var deleting = ref.deleting;
          var deleteCompany = ref.deleteCompany;
return [_c('DeleteDialog',{attrs:{"title":"Möchten Sie diese Gesellschaft wirklich löschen?","name":_vm.company.name,"on-delete":function () { return deleteCompany(_vm.companyId); },"loading":deleting},scopedSlots:_vm._u([{key:"additional",fn:function(){return [_vm._v(" Verbundene Personen, Projekte oder hochgeladene Dokumente bleiben davon unberührt. ")]},proxy:true},{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"red","block":""}},'v-btn',attrs,false),on),[_vm._v("Löschen")])]}}],null,true)})]}}],null,false,2935548451)})],1):_vm._e(),(!_vm.isCompanyMerged && _vm.userHasAdminAccess)?_c('v-card-text',[_c('CompanyMergeProvider',{attrs:{"company-id":_vm.companyId,"company-name":_vm.company.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var merging = ref.merging;
          var mergeCompany = ref.mergeCompany;
return [_c('MergeDialog',{attrs:{"name":_vm.company.name,"on-merge":mergeCompany,"loading":merging},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"orange","block":""}},'v-btn',attrs,false),on),[_vm._v("Gesellschaft verschmelzen")])]}}],null,true)})]}}],null,false,3162639362)})],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }