



























import { defineComponent, PropType } from '@vue/composition-api';
import { BankAccountFragment } from '@/graphql';
import { bankAccountTypes } from '@/constants';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<BankAccountFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      bankAccountTypes,
    };
  },
});
