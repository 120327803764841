import { DocumentType } from '@/graphql';

const documentTypes: { [K in DocumentType]: string } = {
  [DocumentType.Misc]: 'Sonstiges',
  [DocumentType.DebitAgreement]: 'Lastschriftvereinbarung',
  [DocumentType.Excerpt]: 'Handelsregisterauszug',
  [DocumentType.ShareholderResolution]: 'Gesellschafterbeschluss',
  [DocumentType.Protocol]: 'Protokoll',
};

export default documentTypes;
