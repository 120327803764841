













import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { CompanyLegalType } from '@/graphql';
import { companyLegalTypes } from '@/constants';

export default defineComponent({
  props: {
    value: {
      type: String as PropType<CompanyLegalType | null>,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value);

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
    );

    const items = Object.entries(companyLegalTypes).map(([key, value]) => {
      return { text: value, value: key };
    });

    return {
      model,
      items,
      input: (companyLegalType: CompanyLegalType) => {
        emit('input', companyLegalType);
      },
    };
  },
});
