








































import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { PlannedMeasure } from '@/graphql';
import EditableFields from '@/components/Fields/EditableFields.vue';
import EditableCard from '@/components/Cards/EditableCard.vue';
import CompanyListProvider from '@/providers/CompanyListProvider.vue';
import CompanySelect from '@/components/Inputs/CompanySelect.vue';
import PlannedMeasureSourceSelect from '@/components/Inputs/PlannedMeasureSourceSelect.vue';

export type OnSubmitCallback = (data: PlannedMeasure) => Promise<void>;

export default defineComponent({
  components: { PlannedMeasureSourceSelect, CompanySelect, CompanyListProvider, EditableCard, EditableFields },
  props: {
    plannedMeasure: {
      type: Object as PropType<PlannedMeasure | null>,
      required: false,
      default: null,
    },
    onSubmit: {
      type: Function as PropType<OnSubmitCallback>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const model = ref<PlannedMeasure | null>(props.plannedMeasure ? { ...props.plannedMeasure } : null);

    watch(
      () => props.plannedMeasure,
      (newValue) => {
        model.value = newValue ? { ...newValue } : null;
      },
    );

    return {
      model,
      submit: async (after: Function[] = []) => {
        if (model.value) {
          await props.onSubmit(model.value);
        }
        after.forEach((func) => func());
      },
    };
  },
});
