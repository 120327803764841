var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editable)?_c('v-dialog',{attrs:{"max-width":"480"},model:{value:(_vm.upsertDialog),callback:function ($$v) {_vm.upsertDialog=$$v},expression:"upsertDialog"}},[_c('v-card',[_c('ShareholderUpsertProvider',{attrs:{"owned-id":_vm.companyId,"shareholders":_vm.shareholders},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertShareholder = ref.upsertShareholder;
var availableShares = ref.availableShares;
return [_c('v-form',{attrs:{"disabled":upserting},on:{"submit":function($event){$event.preventDefault();return _vm.onUpsert(upsertShareholder)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v("Anteilseigner hinzufügen/editieren")]),_c('v-card-text',[_c('CompanyListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var companies = ref.companies;
return [(_vm.upsertModel)?_c('v-autocomplete',{attrs:{"items":companies,"item-text":"name","item-value":"id","label":"Gesellschaft","loading":loading,"clearable":""},model:{value:(_vm.upsertModel.companyId),callback:function ($$v) {_vm.$set(_vm.upsertModel, "companyId", $$v)},expression:"upsertModel.companyId"}}):_vm._e()]}}],null,true)}),_c('PersonListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var persons = ref.persons;
return [(_vm.upsertModel)?_c('v-autocomplete',{attrs:{"items":persons,"item-text":"name","item-value":"id","label":"Person","loading":loading,"clearable":""},model:{value:(_vm.upsertModel.personId),callback:function ($$v) {_vm.$set(_vm.upsertModel, "personId", $$v)},expression:"upsertModel.personId"}}):_vm._e()]}}],null,true)}),(_vm.upsertModel)?_c('v-text-field',{attrs:{"type":"number","suffix":"%","label":"Anteile in Prozent","rules":[_vm.rules.shares(availableShares).rule],"min":1,"max":_vm.rules.shares(availableShares).max,"messages":("Maximal " + (_vm.rules.shares(availableShares).max) + "% möglich. Davon " + (availableShares.toFixed(2)) + "% frei."),"persistent-hint":""},model:{value:(_vm.upsertModel.shares),callback:function ($$v) {_vm.$set(_vm.upsertModel, "shares", _vm._n($$v))},expression:"upsertModel.shares"}}):_vm._e()],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.close}},[_vm._v("Abbrechen")])],1),_c('v-col',[_c('v-btn',{attrs:{"type":"submit","text":"","block":"","color":"primary","loading":upserting,"disabled":!_vm.valid}},[_vm._v(" Speichern ")])],1)],1)],1)],1)]}}],null,false,339348924)})],1)],1):_vm._e(),_c('v-card',{staticClass:"mx-4"},[_c('v-toolbar',{staticClass:"sticky-header",staticStyle:{"border-width":"0 0 1px 0"},attrs:{"dense":"","flat":"","tile":"","outlined":""}},[_c('v-toolbar-title',{staticClass:"text-body-1"},[_vm._v("Anteilseigner-Struktur")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.editable)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.create}},on),[_c('v-icon',[_vm._v("mdi-wallet-plus")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Neuer Anteilseigner")])])],1),_c('v-progress-linear',{attrs:{"indeterminate":"","active":_vm.loading}}),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}):[_c('h4',{staticClass:"px-4 pt-4 text--secondary"},[_vm._v("Anteilseigner")]),_c('ShareholderList',{attrs:{"company-id":_vm.companyId,"items":_vm.shareholders,"loading":_vm.loading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
var link = ref.link;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":link}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ansehen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.editable)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.update(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ShareholderDeleteProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var deleting = ref.deleting;
var deleteShareholder = ref.deleteShareholder;
return [(_vm.editable)?_c('v-btn',_vm._g({attrs:{"loading":deleting,"icon":""},on:{"click":function($event){return deleteShareholder(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)})]}}],null,true)},[_c('span',[_vm._v("Löschen")])])]}}])}),(_vm.holdings)?[_c('h4',{staticClass:"px-4 pt-4 text--secondary"},[_vm._v("Beteiligungen")]),_c('ShareholderList',{attrs:{"company-id":_vm.companyId,"items":_vm.holdings,"loading":_vm.loading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
var link = ref.link;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":link}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ansehen")])])]}}],null,false,1323013074)})]:_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }