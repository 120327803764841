import { CompanyLegalStatus } from '@/graphql';

export const companyLegalStatus: { [K in CompanyLegalStatus]: string } = {
  [CompanyLegalStatus.InFoundation]: 'In Gründung',
  [CompanyLegalStatus.FoundedOrActive]: 'Gegründet / Aktiv',
  [CompanyLegalStatus.InLiquidation]: 'In Liquidation',
  [CompanyLegalStatus.Liquidated]: 'Liquidiert / Verschmolzen',
};

export default companyLegalStatus;
