import { ActionContext, Module } from 'vuex';
import { RootState } from '@/store/index';
import {
  CreateDocumentDocument,
  CreateDocumentInput,
  CreateDocumentMutation,
  CreateDocumentMutationVariables,
  DeleteDocumentDocument,
  DocumentType,
  DownloadDocumentDocument,
  DownloadDocumentQuery,
  DownloadDocumentQueryVariables,
  ListDocumentParams,
  ListDocumentsDocument,
  ListDocumentsQuery,
  ListDocumentsQueryVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';

type DocumentsState = {};

type Context = ActionContext<DocumentsState, RootState>;

const module: Module<DocumentsState, RootState> = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async downloadDocument({ commit }: Context, variables: DownloadDocumentQueryVariables) {
      const { data } = await apolloClient.query<DownloadDocumentQuery, DownloadDocumentQueryVariables>({
        query: DownloadDocumentDocument,
        variables: variables,
      });

      const { signedUrl } = data.downloadDocument;

      if (typeof signedUrl === 'string') {
        window.open(signedUrl, '_self');
      }
    },

    async uploadDocument(
      { commit, dispatch }: Context,
      payload: { companyId: string; file: File; documentType: DocumentType },
    ) {
      const signedUrl = await dispatch('createDocument', {
        companyId: payload.companyId,
        fileName: payload.file.name,
        fileMimeType: payload.file.type,
        documentType: payload.documentType,
      } as CreateDocumentInput);

      if (typeof signedUrl === 'string') {
        await fetch(signedUrl, {
          method: 'PUT',
          body: payload.file,
        });

        return true;
      }
    },

    async deleteDocument({ commit }: Context, documentId: string) {
      await apolloClient.mutate({
        mutation: DeleteDocumentDocument,
        variables: { documentId },
      });
    },

    async createDocument({ commit }: Context, createDocumentInput: CreateDocumentInput) {
      const { data } = await apolloClient.mutate<CreateDocumentMutation, CreateDocumentMutationVariables>({
        mutation: CreateDocumentDocument,
        variables: {
          createDocumentInput,
        },
      });

      return data?.createDocument?.signedUrl || null;
    },

    async loadDocuments({ commit }: Context, listParams: ListDocumentParams) {
      const { data } = await apolloClient.query<ListDocumentsQuery, ListDocumentsQueryVariables>({
        query: ListDocumentsDocument,
        variables: { listParams },
      });

      return data.listDocuments || [];
    },
  },
};

export default module;
