import Vue from 'vue';
import VueRouter, { RawLocation, Route, RouteConfig, RouterMode } from 'vue-router';
import OidcError from '@/views/OidcError.vue';
import OidcCallback from '@/views/OidcCallback.vue';
import OidcSilentRenew from '@/views/OidcSilentRenew.vue';
import OidcLogout from '@/views/OidcLogout.vue';
import OidcLogoutCallback from '@/views/OidcLogoutCallback.vue';
import Home from '@/views/Home.vue';
import Companies from '@/views/Companies.vue';
import PlannedMeasures from '@/views/PlannedMeasures.vue';
import Persons from '@/views/Persons.vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/companies/:id?',
    name: 'companies',
    component: Companies,
  },
  {
    path: '/persons/:id?',
    name: 'persons',
    component: Persons,
  },
  {
    path: '/planned-measures/:id?',
    name: 'planned-measures',
    component: PlannedMeasures,
  },
  {
    path: '/oidc-callback',
    name: 'oidc-callback',
    component: OidcCallback,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-error',
    name: 'oidc-error',
    component: OidcError,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-silent-renew',
    name: 'oidc-silent-renew',
    component: OidcSilentRenew,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-logout-callback',
    name: 'oidc-logout-callback',
    component: OidcLogoutCallback,
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/oidc-logout',
    name: 'oidc-logout',
    component: OidcLogout,
    meta: {
      isPublic: true,
    },
  },
];

const routerMode: RouterMode = 'history';

const router = new VueRouter({
  mode: routerMode,
  base: process.env.BASE_URL,
  routes,
});

Sentry.init({
  Vue,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'smd.product-services.exporo.io/', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

const createDuplicationRouteGuard = (decorated: Function) => {
  return (location: RawLocation): Promise<Route> => {
    return decorated.call(router, location).catch((err: Error) => {
      if (!VueRouter.isNavigationFailure(err, VueRouter.NavigationFailureType.duplicated)) {
        throw err;
      }
    });
  };
};

VueRouter.prototype.replace = createDuplicationRouteGuard(VueRouter.prototype.replace);
VueRouter.prototype.push = createDuplicationRouteGuard(VueRouter.prototype.push);

export default router;
