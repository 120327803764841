










import { ShareholderFragment, UpsertShareholderInput } from '@/graphql';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export type ShareholderUpsertCallback = (input: UpsertShareholderInput) => Promise<void>;

export default defineComponent({
  props: {
    ownedId: {
      type: String,
      required: true,
    },
    shareholders: {
      type: Array as PropType<ShareholderFragment[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { upsertShareholder } = useActions(['upsertShareholder']);
    const upserting = ref<boolean>(false);

    const upsert: ShareholderUpsertCallback = async (input: UpsertShareholderInput) => {
      upserting.value = true;

      const shareholder: ShareholderFragment | null = await upsertShareholder({
        ...input,
        ownedId: props.ownedId ?? undefined,
      } as UpsertShareholderInput);

      if (shareholder) {
        upserting.value = false;
        emit('upserted', shareholder);
      }
    };

    return {
      upserting,
      upsertShareholder: upsert,
      availableShares: computed(() => {
        const used = props.shareholders
          .filter((value) => value.ownedId === props.ownedId)
          .reduce((previous, shareholder) => previous + shareholder.shares, 0);
        return 100.0 - used;
      }),
    };
  },
});
