var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('SearchProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var search = ref.search;
var results = ref.results;
var loading = ref.loading;
return [_c('Search',{attrs:{"loading":loading,"on-search":search,"results":results}})]}}])}),_c('DashboardProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var companyCount = ref.companyCount;
var plannedMeasureCount = ref.plannedMeasureCount;
var personCount = ref.personCount;
var roleCount = ref.roleCount;
var loading = ref.loading;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"to":{ name: 'companies' },"loading":loading}},[_c('v-card-title',[_vm._v("Gesellschaften "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1),(loading)?_c('v-card-subtitle',[_vm._v("Lädt ...")]):_c('v-card-subtitle',[_vm._v(_vm._s(companyCount)+" Gesellschaften")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"to":{ name: 'planned-measures' },"loading":loading}},[_c('v-card-title',[_vm._v("Geplante Maßnahmen "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1),(loading)?_c('v-card-subtitle',[_vm._v("Lädt ...")]):_c('v-card-subtitle',[_vm._v(_vm._s(plannedMeasureCount)+" geplante Maßnahmen")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"to":{ name: 'persons' },"loading":loading}},[_c('v-card-title',[_vm._v("Personen "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1),(loading)?_c('v-card-subtitle',[_vm._v("Lädt ...")]):_c('v-card-subtitle',[_vm._v(_vm._s(personCount)+" Personen")])],1)],1)],1)]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('CompanyListProvider',{attrs:{"take":10,"status":_vm.status},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var companies = ref.companies;
return [_c('v-card',{attrs:{"loading":loading}},[_c('v-card-title',[_vm._v("Nicht verwendete Gesellschaften")]),_c('v-card-text',[_c('CompanyList',{attrs:{"loading":loading,"items":companies,"linked":""}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }