import { Role } from '@/graphql';

const roles: { [K in Role]: string } = {
  [Role.Complementary]: 'Komplementär GmbH',
  [Role.AdministrativeBoard]: 'Verwaltungsrat',
  [Role.LimitedPartner]: 'Geschäftsführende Kommanditistin GmbH',
  [Role.PowerOfAttorney]: 'Allgemeine Handlungsvollmacht',
  [Role.Chairman]: 'Vorstand',
  [Role.Ceo]: 'Geschäftsführer',
  [Role.Proxy]: 'Prokurist',
};

export default roles;
