






import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { Company, CompanyFragment, UpsertCompanyInput } from '@/graphql';

export default defineComponent({
  setup(props, { emit }) {
    const { upsertCompany } = useActions(['upsertCompany']);
    const upserting = ref<boolean>(false);

    return {
      upserting,
      upsertCompany: async (model: Partial<Company>) => {
        upserting.value = true;

        const company: CompanyFragment | null = await upsertCompany({
          id: model.id,
          status: model.status,
          legalStatus: model.legalStatus,
          name: model.name,
          lei: model.lei,
          leiValidUntil: model.leiValidUntil,
          purpose: model.purpose,
          legalType: model.legalType,
          capital: model.capital || null,
          taxationAuthority: model.taxationAuthority,
          taxId: model.taxId,
          FsakId: model.FsakId,
          vatNumber: model.vatNumber,
          registrationNumber: model.registrationNumber,
          registrationCourt: model.registrationCourt,
          creditorId: model.creditorId,
          wkn: model.wkn,
          isin: model.isin,
          bbankNumber: model.bbankNumber,
          ihkNumber: model.ihkNumber,
          usage: model.usage,
          address1: model.address1,
          address2: model.address2,
          city: model.city,
          state: model.state,
          zipCode: model.zipCode,
          country: model.country,
          paymentId: model.paymentId,
          mangoPayUserId: model.mangoPayUserId,
          formerName: model.formerName,
          registeredAt: model.registeredAt,
          foundedAt: model.foundedAt,
        } as UpsertCompanyInput);

        upserting.value = false;

        if (company) {
          emit('upserted', company);
        }
      },
    };
  },
});
