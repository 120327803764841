






import { CompanyFragment } from '@/graphql';
import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    companyId: {
      type: String,
    },
  },
  setup(props, { root, emit }) {
    const { loadCompany } = useActions(['loadCompany']);

    const company = ref<CompanyFragment | null>(null);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      if (!props.companyId) {
        return;
      }

      loading.value = true;
      company.value = await loadCompany(props.companyId);
      loading.value = false;

      emit('loaded', company.value);
    };

    watch([() => props.companyId], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteCompany', 'upsertCompany'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      company,
      loading,
    };
  },
});
