





























import { defineComponent, ref } from '@vue/composition-api';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import DocumentUploadProvider from '@/providers/DocumentUploadProvider.vue';
import { DocumentType } from '@/graphql';
import { documentTypes } from '@/constants';

export default defineComponent({
  components: { DocumentUploadProvider, Tooltip },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props, { emit }) {
    const documentType = ref(DocumentType.Misc);
    const input = ref<File | undefined>();
    const inputRef = ref();

    const showUploadDialog = ref(false);

    const openDialog = () => {
      showUploadDialog.value = true;
    };

    const closeDialog = () => {
      showUploadDialog.value = false;
    };

    const documentTypeItems = Object.entries(documentTypes).map(([key, value]) => {
      return { text: value, value: key };
    });

    const submit = async (submitAction: Function) => {
      if (input.value instanceof File) {
        await submitAction(props.companyId, input.value, documentType.value);
        emit('upload', input.value.name);
        input.value = undefined;
        documentType.value = DocumentType.Misc;
      }
      closeDialog();
    };

    return {
      inputRef,
      input,
      documentType,
      showUploadDialog,
      documentTypeItems,
      submit,
      openDialog,
      closeDialog,
    };
  },
});
