














import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { PersonFragment } from '@/graphql';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<PersonFragment | null>,
      required: false,
      default: null,
    },
    persons: {
      type: Array as PropType<PersonFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value);

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
    );

    return {
      model,
      input: (person: PersonFragment) => {
        emit('input', person);
      },
      clear: () => {
        props.value = null;
      },
    };
  },
});
