var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","right":"","temporary":"","hide-overlay":"","stateless":"","width":"600","value":_vm.open},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('Toolbar',{attrs:{"title":_vm.plannedMeasure ? _vm.plannedMeasure.name : ''}},[_c('Tooltip',{attrs:{"text":"Schließen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onClose}},on),[_c('v-icon',[_vm._v("mdi-arrow-collapse-right")])],1)]}}])})],1)]},proxy:true}])},[(!_vm.userHasEditAccess)?_c('v-alert',{attrs:{"dense":"","text":"","type":"info","rounded":"0"}},[_vm._v(" Sie können diese Maßnahme nicht bearbeiten, da Sie nur Lesezugriff haben. ")]):_vm._e(),(_vm.plannedMeasureId)?_c('PlannedMeasureUpsertProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertPlannedMeasure = ref.upsertPlannedMeasure;
return [_c('PlannedMeasureForm',{staticClass:"ma-4",attrs:{"planned-measure":_vm.plannedMeasure,"on-submit":upsertPlannedMeasure,"disabled":upserting,"loading":_vm.loading,"editable":_vm.userHasEditAccess},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('FieldList',{attrs:{"loading":_vm.loading,"item":_vm.plannedMeasure,"fields":{
            name: 'Name',
            type: 'Art der Maßnahme',
            referenceSource: 'Quelle',
            referenceId: 'Quellen ID',
            company: 'Gesellschaft',
            createdAt: 'Erstellt am',
            updatedAt: 'Bearbeitet am',
          }},scopedSlots:_vm._u([{key:"referenceSource",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('PlannedMeasureSourceChip',{attrs:{"small":"","source":item.referenceSource}})],1)]}},{key:"company",fn:function(ref){
          var item = ref.item;
return [(item.company)?_c('div',[_c('v-chip',{attrs:{"to":{ name: 'companies', params: { id: item.company.id } },"text":"","link":"","small":""}},[_vm._v(" "+_vm._s(item.company.name)+" ")])],1):_vm._e()]}},{key:"createdAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.createdAt))+" ")]}},{key:"updatedAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.updatedAt))+" ")]}}],null,true)})]},proxy:true}],null,true)})]}}],null,false,3532778826)}):_vm._e(),(_vm.plannedMeasureId)?_c('ContactListProvider',{attrs:{"planned-measure-id":_vm.plannedMeasureId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var contacts = ref.contacts;
          var loading = ref.loading;
return [_c('ContactsCard',{staticClass:"ma-4",attrs:{"planned-measure-id":_vm.plannedMeasureId,"contacts":contacts,"loading":loading,"editable":_vm.userHasEditAccess}})]}}],null,false,1186336701)}):_vm._e(),(_vm.plannedMeasureId && _vm.plannedMeasure && _vm.userHasAdminAccess)?_c('v-card-text',[_c('PlannedMeasureDeleteProvider',{on:{"deleted":_vm.onClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var deleting = ref.deleting;
          var deletePlannedMeasure = ref.deletePlannedMeasure;
return [_c('DeleteDialog',{attrs:{"title":"Möchten Sie diese Maßnahme wirklich löschen?","name":_vm.plannedMeasure.name,"on-delete":function () { return deletePlannedMeasure(_vm.plannedMeasureId); },"loading":deleting},scopedSlots:_vm._u([{key:"additional",fn:function(){return [_vm._v(" Dadurch werden auch alle Verweise auf diese Maßnahme entfernt. ")]},proxy:true},{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"red","block":""}},'v-btn',attrs,false),on),[_vm._v("Löschen")])]}}],null,true)})]}}],null,false,1586975483)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }