import { BankAccountType } from '@/graphql';

const bankAccountTypes: { [K in BankAccountType]: string } = {
  [BankAccountType.Business]: 'Geschäftskonto',
  [BankAccountType.Loan]: 'Darlehenskonto',
  [BankAccountType.Management]: 'Bewirtschaftungskonto',
  [BankAccountType.MaintenanceReserves]: 'Instandhaltungsrücklagenkonto',
};

export default bankAccountTypes;
