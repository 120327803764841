var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","right":"","temporary":"","hide-overlay":"","stateless":"","width":"600","value":_vm.open},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('Toolbar',{attrs:{"title":_vm.person ? _vm.person.name : ''}},[_c('Tooltip',{attrs:{"text":"Schließen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onClose}},on),[_c('v-icon',[_vm._v("mdi-arrow-collapse-right")])],1)]}}])})],1)]},proxy:true}])},[(!_vm.userHasEditAccess)?_c('v-alert',{attrs:{"dense":"","text":"","type":"info","rounded":"0"}},[_vm._v(" Sie können diese Firma nicht bearbeiten, da Sie nur Lesezugriff haben. ")]):_vm._e(),(_vm.personId)?_c('PersonUpsertProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertPerson = ref.upsertPerson;
return [_c('PersonForm',{staticClass:"ma-4",attrs:{"person":_vm.person,"on-submit":upsertPerson,"disabled":upserting,"loading":_vm.loading,"editable":_vm.userHasEditAccess},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('FieldList',{attrs:{"loading":_vm.loading,"item":_vm.person,"fields":{
            name: 'Name',
            createdAt: 'Erstellt am',
            updatedAt: 'Bearbeitet am',
          }},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.createdAt))+" ")]}},{key:"updatedAt",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.updatedAt))+" ")]}}],null,true)})]},proxy:true}],null,true)})]}}],null,false,494522974)}):_vm._e(),_c('v-card',{staticClass:"ma-4"},[_c('Toolbar',{staticClass:"sticky-header",attrs:{"dense":"","loading":_vm.loading,"title":"Rollen"}}),(_vm.personId)?_c('AuthorityListProvider',{attrs:{"person-id":_vm.personId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var authorities = ref.authorities;
          var loading = ref.loading;
return [_c('AuthorityList',{attrs:{"title":"company","subtitle":"role","items":authorities,"loading":loading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var link = ref.link;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":link}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ansehen")])])]}}],null,true)})]}}],null,false,1485237114)}):_vm._e()],1),(_vm.userHasAdminAccess)?_c('v-card',{staticClass:"ma-4"},[_c('Toolbar',{staticClass:"sticky-header",attrs:{"dense":"","loading":_vm.loading,"title":"Anteilseigner-Struktur"}}),(_vm.personId)?_c('ShareholderListProvider',{attrs:{"person-id":_vm.personId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var shareholders = ref.shareholders;
          var loading = ref.loading;
return [_c('ShareholderList',{attrs:{"items":shareholders,"loading":loading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var link = ref.link;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":link}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ansehen")])])]}}],null,true)})]}}],null,false,2735009993)}):_vm._e()],1):_vm._e(),_c('v-card',{staticClass:"ma-4"},[_c('Toolbar',{staticClass:"sticky-header",attrs:{"dense":"","loading":_vm.loading,"title":"Bankvollmachten"}}),(_vm.personId)?_c('BankMandateListProvider',{attrs:{"person-id":_vm.personId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var bankMandates = ref.bankMandates;
          var loading = ref.loading;
return [_c('BankMandateList',{attrs:{"items":bankMandates,"loading":loading,"removable":false},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var link = ref.link;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":link}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ansehen")])])]}}],null,true)})]}}],null,false,4104089134)}):_vm._e()],1),_c('v-divider'),(_vm.personId && _vm.person && _vm.userHasAdminAccess)?_c('v-card-text',[_c('PersonDeleteProvider',{on:{"deleted":_vm.onClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var deleting = ref.deleting;
          var deletePerson = ref.deletePerson;
return [_c('DeleteDialog',{attrs:{"title":"Möchten Sie diese Person wirklich löschen?","name":_vm.person.name,"on-delete":function () { return deletePerson(_vm.personId); },"loading":deleting},scopedSlots:_vm._u([{key:"additional",fn:function(){return [_vm._v(" Die Person wird dadurch auch aus allen Firmen entfernt ")]},proxy:true},{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"red","block":""}},'v-btn',attrs,false),on),[_vm._v("Löschen")])]}}],null,true)})]}}],null,false,3938078216)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }