






import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { AuthorityFragment } from '@/graphql';

export default defineComponent({
  props: {
    companyId: {
      type: String,
      required: false,
      default: null,
    },
    personId: {
      type: String,
      required: false,
      default: null,
    },
    take: {
      type: Number,
    },
    skip: {
      type: Number,
    },
  },
  setup(props, { root, emit }) {
    const { loadAuthorities } = useActions(['loadAuthorities']);

    const authorities = ref<AuthorityFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { companyId, personId, skip, take } = props;

      loading.value = true;

      authorities.value = await loadAuthorities({
        companyId,
        personId,
        skip,
        take,
      });

      loading.value = false;

      emit('loaded');
    };

    watch([() => props.companyId, () => props.personId, () => props.skip, () => props.take], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteAuthority', 'upsertAuthority'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      authorities,
      loading,
    };
  },
});
