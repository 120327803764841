






import { Contact } from '@/graphql';
import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    plannedMeasureId: {
      type: String,
    },
    take: {
      type: Number,
    },
    skip: {
      type: Number,
    },
  },
  setup(props, { emit, root }) {
    const { loadContacts } = useActions(['loadContacts']);

    const contacts = ref<Contact[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { plannedMeasureId, skip, take } = props;

      loading.value = true;

      contacts.value = await loadContacts({
        plannedMeasureId,
        skip,
        take,
      });

      loading.value = false;

      emit('loaded');
    };

    watch([() => props.plannedMeasureId, () => props.take, () => props.take], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteContact', 'upsertContact'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      contacts,
      loading,
    };
  },
});
