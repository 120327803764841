import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // @TODO - automate parameterization of service subdomain
      tracingOrigins: ['localhost', 'smd.product-services.exporo.io', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Exporo SMD';
  next();
});

Vue.use(VueCompositionAPI);

// @TODO - don't do this check at all, we will always use the same authentication method
if (process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE === 'OPENID_CONNECT') {
  router.beforeEach(vuexOidcCreateRouterMiddleware(store));
}

Vue.filter('dateFormat', (date: string | number | Date | null, format = 'DD.MM.YYYY') => {
  if (date === null) {
    return '';
  }

  return dayjs(date).format(format);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  vuetify,
}).$mount('#app');
