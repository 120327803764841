import appSyncConfig from '../app-sync.config';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { notify } from '@/store';
import { Query, Mutation } from '@/graphql';

export type QueryFieldName = keyof Omit<Query, '__typename'>;
export type MutationFieldName = keyof Omit<Mutation, '__typename'>;

export type QueryResponseData = { [K in QueryFieldName]?: Query[K] };
export type MutationResponseData = { [K in MutationFieldName]?: Mutation[K] };
export type GraphQLResponseData = QueryResponseData | MutationResponseData;

const link = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    notify({ message: `SMD ist gerade nicht erreichbar. Bitte versuche es später erneut.`, color: 'red' });
  }),
  createAuthLink({ url: appSyncConfig.url, region: appSyncConfig.region, auth: appSyncConfig.auth }),
  createHttpLink({ uri: appSyncConfig.url }),
]);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    resultCaching: false,
  }),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});
