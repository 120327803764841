






import { PlannedMeasure, PlannedMeasureFragment, PlannedMeasureSource } from '@/graphql';
import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  setup(props, { emit }) {
    const { upsertPlannedMeasure } = useActions(['upsertPlannedMeasure']);

    const upserting = ref<boolean>(false);

    return {
      upserting,
      upsertPlannedMeasure: async (model: Partial<PlannedMeasure>) => {
        upserting.value = true;

        const plannedMeasure: PlannedMeasureFragment | null = await upsertPlannedMeasure({
          id: model.id,
          name: model.name,
          type: model.type ?? '',
          companyId: model.company ? model.company.id : null,
          referenceSource: model.referenceSource ?? PlannedMeasureSource.Immotool,
          referenceId: model.referenceId ?? '',
        });

        if (plannedMeasure) {
          upserting.value = false;
          emit('upserted', plannedMeasure);
        }
      },
    };
  },
});
