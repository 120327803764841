import { ActionContext, Module } from 'vuex';
import { DashboardDocument, DashboardQuery, DashboardQueryVariables } from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type DashboardState = {};

type Context = ActionContext<DashboardState, RootState>;

export default {
  actions: {
    async loadDashboardData() {
      const { data } = await apolloClient.query<DashboardQuery, DashboardQueryVariables>({
        query: DashboardDocument,
        variables: {},
      });

      return data || null;
    },
  },
} as Module<DashboardState, RootState>;
