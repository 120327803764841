






import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { ShareholderFragment } from '@/graphql';

export default defineComponent({
  props: {
    ownedId: {
      type: String,
      required: false,
      default: null,
    },
    companyId: {
      type: String,
      required: false,
      default: null,
    },
    personId: {
      type: String,
      required: false,
      default: null,
    },
    take: {
      type: Number,
    },
    skip: {
      type: Number,
    },
  },
  setup(props, { root, emit }) {
    const { loadShareholders } = useActions(['loadShareholders']);

    const shareholders = ref<ShareholderFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { ownedId, companyId, personId, skip, take } = props;

      loading.value = true;

      shareholders.value = await loadShareholders({
        ownedId,
        companyId,
        personId,
        skip,
        take,
      });

      loading.value = false;

      emit('loaded');
    };

    watch(
      [() => props.ownedId, () => props.companyId, () => props.personId, () => props.skip, () => props.take],
      fetchData,
    );

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteShareholder', 'upsertShareholder'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      shareholders: computed<ShareholderFragment[]>(() => {
        return shareholders.value.filter(
          (shareholder: ShareholderFragment) => !props.ownedId || shareholder.ownedId === props.ownedId,
        );
      }),
      holdings: computed<ShareholderFragment[] | null>(() => {
        if (!props.ownedId) {
          return null;
        }

        return shareholders.value.filter(
          (shareholder: ShareholderFragment) => props.ownedId && shareholder.ownedId !== props.ownedId,
        );
      }),
      loading,
    };
  },
});
