import { ActionContext, Module } from 'vuex';
import { SearchDocument, SearchQuery, SearchQueryVariables } from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type SearchState = {};

type Context = ActionContext<SearchState, RootState>;

export default {
  state: {},
  actions: {
    async search({ commit }: Context, searchQuery: string | null) {
      const { data } = await apolloClient.query<SearchQuery, SearchQueryVariables>({
        query: SearchDocument,
        variables: { searchQuery: searchQuery || '' },
      });

      return data || null;
    },
  },
} as Module<SearchState, RootState>;
