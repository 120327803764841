var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{scopedSlots:_vm._u([{key:"navigation-drawer",fn:function(){return [_c('RouteAware',{attrs:{"param":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var paramValue = ref.paramValue;
return [_c('PlannedMeasureProvider',{attrs:{"planned-measure-id":paramValue},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var plannedMeasure = ref.plannedMeasure;
var loading = ref.loading;
return [_c('PlannedMeasureDrawer',{attrs:{"open":!!paramValue,"planned-measure":plannedMeasure,"planned-measure-id":paramValue,"loading":loading},on:{"close":_vm.onClose}})]}}],null,true)})]}}])})]},proxy:true}])},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('h1',[_vm._v("Geplante Maßnahmen")])])],1),_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(_vm.userHasEditAccess)?_c('PlannedMeasureUpsertProvider',{on:{"upserted":function($event){return _vm.onSelect($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertPlannedMeasure = ref.upsertPlannedMeasure;
return [_c('v-btn',{attrs:{"loading":upserting,"disabled":upserting,"color":"primary"},on:{"click":function($event){return upsertPlannedMeasure({ name: 'Neue Maßnahme' })}}},[_vm._v(" Maßnahme hinzufügen ")])]}}],null,false,3216781903)}):_vm._e()],1)],1)],1),_c('v-card',[_c('PlannedMeasureListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var plannedMeasures = ref.plannedMeasures;
var loading = ref.loading;
return [_c('v-data-table',{staticClass:"table--clickable",attrs:{"headers":[
          { text: 'Projekt', value: 'name' },
          { text: 'Art der Maßnahme', value: 'type' },
          { text: 'Gesellschaft', value: 'company' },
          { text: 'Quelle', value: 'referenceSource', filter: _vm.filter('referenceSource') },
          { text: 'id', value: 'referenceId' } ],"items":plannedMeasures,"loading":loading,"search":_vm.search,"sort-desc":false,"sort-by":"name"},on:{"click:row":_vm.onSelect},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suche","prepend-inner-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"header.referenceSource",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('Facet',{attrs:{"label":"Quelle","filters":_vm.referenceSources},model:{value:(_vm.facets.referenceSource),callback:function ($$v) {_vm.$set(_vm.facets, "referenceSource", $$v)},expression:"facets.referenceSource"}})]}},{key:"no-data",fn:function(){return [_vm._v("Keine Daten")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("Die Treffer für "+_vm._s(_vm.search))]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"loading":loading,"type":"table-tbody"}})]},proxy:true},{key:"item.company",fn:function(ref){
        var item = ref.item;
return [(item.company)?[_c('v-chip',{attrs:{"to":{ name: 'companies', params: { id: item.company.id } },"text":"","small":"","link":""}},[_vm._v(" "+_vm._s(item.company.name)+" ")])]:_vm._e()]}},{key:"item.referenceSource",fn:function(ref){
        var item = ref.item;
return [(item.referenceSource)?[_c('PlannedMeasureSourceChip',{attrs:{"source":item.referenceSource}})]:_vm._e()]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }