






import { Person } from '@/graphql';
import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    personId: {
      type: String,
    },
  },
  setup(props, { root, emit }) {
    const { loadPerson } = useActions(['loadPerson']);

    const person = ref<Person | null>(null);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      if (!props.personId) {
        return;
      }

      loading.value = true;
      person.value = await loadPerson(props.personId);
      loading.value = false;

      emit('loaded', person.value);
    };

    watch([() => props.personId], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deletePerson', 'upsertPerson'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      person,
      loading,
    };
  },
});
