var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('Toolbar',{staticClass:"sticky-header",attrs:{"dense":"","loading":_vm.loading,"title":"Rollen"}},[(_vm.editable)?_c('Tooltip',{attrs:{"text":"Neue Rolle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-right":"-12px"},attrs:{"icon":""},on:{"click":function($event){return _vm.create()}}},on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}],null,false,2812075180)}):_vm._e()],1),_c('v-dialog',{attrs:{"value":!!_vm.upsertModel,"max-width":400,"persistent":""}},[_c('AuthorityUpsertProvider',{attrs:{"company-id":_vm.companyId,"legal-type":_vm.legalType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upsert = ref.upsert;
var upserting = ref.upserting;
return [(_vm.upsertModel)?_c('v-card',{attrs:{"disabled":upserting,"loading":upserting}},[_c('v-card-title',[_vm._v("Test")]),_c('v-card-text',[_c('PersonListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var persons = ref.persons;
var loadingPersons = ref.loading;
return [_c('AuthorityForm',{attrs:{"authorities":_vm.authorities,"model":_vm.upsertModel,"disabled":upserting,"on-submit":upsert,"persons":persons,"loading":loadingPersons,"legal-type":_vm.legalType},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var submit = ref.submit;
return [_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.unselect}},[_vm._v("Abbrechen")])],1),_c('v-col',[_c('v-btn',{attrs:{"text":"","block":"","loading":upserting},on:{"click":function($event){return submit([_vm.unselect])}}},[_vm._v("Speichern")])],1)],1)]}}],null,true)})]}}],null,true)})],1)],1):_vm._e()]}}])})],1),_c('AuthorityList',{attrs:{"title":"person","subtitle":"role","items":_vm.authorities,"loading":_vm.loading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
var link = ref.link;
return [_c('AuthorityDeleteProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var deleteAuthority = ref.deleteAuthority;
var deleting = ref.deleting;
return [(_vm.editable)?_c('Tooltip',{attrs:{"text":"Bearbeiten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.select(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}):_vm._e(),_c('Tooltip',{attrs:{"text":"Ansehen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":link}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)}),(_vm.editable)?_c('Tooltip',{attrs:{"text":"Löschen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":deleting},on:{"click":function($event){return deleteAuthority(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }