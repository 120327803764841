











import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Contact, UpsertContactInput } from '@/graphql';

export type OnSubmitCallback = (data: UpsertContactInput) => Promise<void>;

export default defineComponent({
  props: {
    contact: {
      type: Object as PropType<Contact>,
      required: true,
    },
    onSubmit: {
      type: Function as PropType<OnSubmitCallback>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const model = ref<Contact>({ ...props.contact });

    watch(
      () => props.contact,
      (newValue) => {
        model.value = { ...newValue };
      },
    );

    return {
      model,
      submit: async (after: Function[] = []) => {
        await props.onSubmit(model.value);
        after.forEach((func) => func());
      },
    };
  },
});
