import { ActionContext, Module } from 'vuex';
import {
  DeletePlannedMeasureDocument,
  DeletePlannedMeasureMutation,
  DeletePlannedMeasureMutationVariables,
  GetPlannedMeasureByIdDocument,
  GetPlannedMeasureByIdQuery,
  GetPlannedMeasureByIdQueryVariables,
  ListPlannedMeasureParams,
  ListPlannedMeasuresDocument,
  ListPlannedMeasuresQuery,
  ListPlannedMeasuresQueryVariables,
  UpsertPlannedMeasureDocument,
  UpsertPlannedMeasureInput,
  UpsertPlannedMeasureMutation,
  UpsertPlannedMeasureMutationVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type PlannedMeasuresState = {};

type Context = ActionContext<PlannedMeasuresState, RootState>;

export default {
  actions: {
    async loadPlannedMeasures({ commit }: Context, listParams: ListPlannedMeasureParams = {}) {
      const { data } = await apolloClient.query<ListPlannedMeasuresQuery, ListPlannedMeasuresQueryVariables>({
        query: ListPlannedMeasuresDocument,
        variables: { listParams },
      });

      return data.listPlannedMeasures || [];
    },

    async loadPlannedMeasure({ commit }: Context, plannedMeasureId: string) {
      const { data } = await apolloClient.query<GetPlannedMeasureByIdQuery, GetPlannedMeasureByIdQueryVariables>({
        query: GetPlannedMeasureByIdDocument,
        variables: { plannedMeasureId },
      });

      return data.getPlannedMeasureById || null;
    },

    async upsertPlannedMeasure({ commit }: Context, upsertPlannedMeasureInput: UpsertPlannedMeasureInput) {
      const { data } = await apolloClient.mutate<UpsertPlannedMeasureMutation, UpsertPlannedMeasureMutationVariables>({
        mutation: UpsertPlannedMeasureDocument,
        variables: { upsertPlannedMeasureInput },
      });

      return data?.upsertPlannedMeasure.data ?? null;
    },

    async deletePlannedMeasure({ commit }: Context, plannedMeasureId: string) {
      return apolloClient.mutate<DeletePlannedMeasureMutation, DeletePlannedMeasureMutationVariables>({
        mutation: DeletePlannedMeasureDocument,
        variables: { plannedMeasureId },
      });
    },
  },
} as Module<PlannedMeasuresState, RootState>;
