import { ActionContext, Module } from 'vuex';
import {
  DeleteBankMandateDocument,
  DeleteBankMandateMutation,
  DeleteBankMandateMutationVariables,
  ListBankMandateParams,
  ListBankMandatesDocument,
  ListBankMandatesQuery,
  ListBankMandatesQueryVariables,
  UpsertBankMandateDocument,
  UpsertBankMandateInput,
  UpsertBankMandateMutation,
  UpsertBankMandateMutationVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type BankMandatesState = {};

type Context = ActionContext<BankMandatesState, RootState>;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async loadBankMandates({ commit }: Context, listParams: ListBankMandateParams = {}) {
      const { data } = await apolloClient.query<ListBankMandatesQuery, ListBankMandatesQueryVariables>({
        query: ListBankMandatesDocument,
        variables: { listParams },
      });

      return data.listBankMandates || [];
    },

    async upsertBankMandate({ commit }: Context, upsertBankMandateInput: UpsertBankMandateInput) {
      const { data } = await apolloClient.mutate<UpsertBankMandateMutation, UpsertBankMandateMutationVariables>({
        mutation: UpsertBankMandateDocument,
        variables: { upsertBankMandateInput },
      });

      return data?.upsertBankMandate.data || null;
    },

    async deleteBankMandate({ commit }: Context, bankMandateId: string) {
      const { data } = await apolloClient.mutate<DeleteBankMandateMutation, DeleteBankMandateMutationVariables>({
        mutation: DeleteBankMandateDocument,
        variables: { bankMandateId },
      });

      return data?.deleteBankMandate.id || null;
    },
  },
} as Module<BankMandatesState, RootState>;
