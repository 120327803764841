import { ActionContext, Module } from 'vuex';
import {
  DeleteCompanyDocument,
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables,
  GetCompanyByIdDocument,
  GetCompanyByIdQuery,
  GetCompanyByIdQueryVariables,
  ListCompaniesDocument,
  ListCompaniesQuery,
  ListCompaniesQueryVariables,
  ListCompanyParams,
  UpsertCompanyDocument,
  UpsertCompanyInput,
  UpsertCompanyMutation,
  UpsertCompanyMutationVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type CompaniesState = {};

type Context = ActionContext<CompaniesState, RootState>;

export default {
  state: {
    deletingCompany: false,
  },
  actions: {
    async loadCompanies({ commit }: Context, listParams: ListCompanyParams = {}) {
      const { data } = await apolloClient.query<ListCompaniesQuery, ListCompaniesQueryVariables>({
        query: ListCompaniesDocument,
        variables: { listParams },
      });

      return data.listCompanies ?? [];
    },

    async loadCompany({ commit }: Context, companyId: string) {
      const { data } = await apolloClient.query<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>({
        query: GetCompanyByIdDocument,
        variables: { companyId },
      });

      return data.getCompanyById ?? null;
    },

    async upsertCompany({ commit, dispatch }: Context, upsertCompanyInput: UpsertCompanyInput) {
      const { data } = await apolloClient.mutate<UpsertCompanyMutation, UpsertCompanyMutationVariables>({
        mutation: UpsertCompanyDocument,
        variables: { upsertCompanyInput },
      });

      return data?.upsertCompany?.data ?? null;
    },

    async deleteCompany({ commit }: Context, companyId: string) {
      return apolloClient.mutate<DeleteCompanyMutation, DeleteCompanyMutationVariables>({
        mutation: DeleteCompanyDocument,
        variables: { companyId },
      });
    },
  },
} as Module<CompaniesState, RootState>;
