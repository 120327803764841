





import { computed, defineComponent, PropType } from '@vue/composition-api';
import { PlannedMeasureSource } from '@/graphql';
import { plannedMeasureSources } from '@/constants';

export default defineComponent({
  props: {
    source: {
      type: String as PropType<PlannedMeasureSource>,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const sourceProps: { [key: string]: object } = Object.fromEntries(
      Object.entries(plannedMeasureSources).map(([key, value]) => [
        key,
        {
          color: 'blue lighten-1',
          textColor: 'white',
          label: value,
        },
      ]),
    );

    return {
      state: computed(() => {
        return (
          sourceProps[props.source] || {
            color: 'grey',
            textColor: 'white',
            label: props.source || 'Unbekannt',
          }
        );
      }),
    };
  },
});
