





















import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { PlannedMeasureSource } from '@/graphql';
import { plannedMeasureSources } from '@/constants';
import PlannedMeasureSourceChip from '@/components/Chips/PlannedMeasureSourceChip.vue';

export default defineComponent({
  components: { PlannedMeasureSourceChip },
  props: {
    value: {
      type: String as PropType<PlannedMeasureSource | null>,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const model = ref<PlannedMeasureSource | null>(props.value);

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
    );

    return {
      model,
      items: computed(() => {
        return Object.keys(plannedMeasureSources);
      }),
      input: (plannedMeasureSource: PlannedMeasureSource) => {
        emit('input', plannedMeasureSource);
      },
    };
  },
});
