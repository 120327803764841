import { ActionContext, Module } from 'vuex';
import {
  DeletePersonDocument,
  DeletePersonMutation,
  DeletePersonMutationVariables,
  GetPersonByIdDocument,
  GetPersonByIdQuery,
  GetPersonByIdQueryVariables,
  ListPersonParams,
  ListPersonsDocument,
  ListPersonsQuery,
  ListPersonsQueryVariables,
  UpsertPersonDocument,
  UpsertPersonInput,
  UpsertPersonMutation,
  UpsertPersonMutationVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type PersonsState = {};

type Context = ActionContext<PersonsState, RootState>;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async loadPersons({ commit }: Context, listParams: ListPersonParams = {}) {
      const { data } = await apolloClient.query<ListPersonsQuery, ListPersonsQueryVariables>({
        query: ListPersonsDocument,
        variables: { listParams },
      });

      return data.listPersons || [];
    },

    async loadPerson({ commit }: Context, personId: string) {
      const { data } = await apolloClient.query<GetPersonByIdQuery, GetPersonByIdQueryVariables>({
        query: GetPersonByIdDocument,
        variables: { personId },
      });

      return data.getPersonById || null;
    },

    async upsertPerson({ commit }: Context, upsertPersonInput: UpsertPersonInput) {
      const { data } = await apolloClient.mutate<UpsertPersonMutation, UpsertPersonMutationVariables>({
        mutation: UpsertPersonDocument,
        variables: { upsertPersonInput },
      });

      return data?.upsertPerson.data || null;
    },

    async deletePerson({ commit }: Context, personId: string) {
      const { data } = await apolloClient.mutate<DeletePersonMutation, DeletePersonMutationVariables>({
        mutation: DeletePersonDocument,
        variables: { personId },
      });

      return data?.deletePerson.id || null;
    },
  },
} as Module<PersonsState, RootState>;
