






import { PlannedMeasureFragment } from '@/graphql';
import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    plannedMeasureId: {
      type: String,
    },
  },
  setup(props, { root, emit }) {
    const { loadPlannedMeasure } = useActions(['loadPlannedMeasure']);

    const plannedMeasure = ref<PlannedMeasureFragment | null>(null);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      if (!props.plannedMeasureId) {
        return;
      }

      loading.value = true;
      plannedMeasure.value = await loadPlannedMeasure(props.plannedMeasureId);
      loading.value = false;

      emit('loaded', plannedMeasure.value);
    };

    watch([() => props.plannedMeasureId], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deletePlannedMeasure', 'upsertPlannedMeasure'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      plannedMeasure,
      loading,
    };
  },
});
