















































































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import { ShareholderFragment, UpsertShareholderInput } from '@/graphql';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import CompanyListProvider from '@/providers/CompanyListProvider.vue';
import PersonListProvider from '@/providers/PersonListProvider.vue';
import PersonSelect from '@/components/Inputs/PersonSelect.vue';
import CompanySelect from '@/components/Inputs/CompanySelect.vue';
import ShareholderDeleteProvider from '@/providers/ShareholderDeleteProvider.vue';
import ShareholderUpsertProvider, { ShareholderUpsertCallback } from '@/providers/ShareholderUpsertProvider.vue';
import ShareholderList from '@/components/Lists/ShareholderList.vue';

export default defineComponent({
  components: {
    ShareholderList,
    ShareholderUpsertProvider,
    ShareholderDeleteProvider,
    CompanySelect,
    PersonSelect,
    PersonListProvider,
    CompanyListProvider,
    Tooltip,
    Toolbar,
  },
  props: {
    companyId: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    shareholders: {
      type: Array as PropType<ShareholderFragment[]>,
      required: true,
    },
    holdings: {
      type: Array as PropType<ShareholderFragment[] | null>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const upsertDialog = ref<boolean>(false);
    const selected = ref<ShareholderFragment | null>(null);
    const upsertModel = ref<UpsertShareholderInput | null>(null);

    const close = () => {
      upsertDialog.value = false;
      upsertModel.value = null;
      selected.value = null;
    };

    return {
      onUpsert: async (callback: ShareholderUpsertCallback) => {
        if (!upsertModel.value) {
          return;
        }

        await callback(upsertModel.value);
        close();
      },
      create: () => {
        if (!props.companyId) {
          return;
        }

        upsertModel.value = {
          ownedId: props.companyId,
          shares: 0,
        };

        upsertDialog.value = true;
      },
      update: (shareholder: ShareholderFragment) => {
        upsertModel.value = {
          id: shareholder.id,
          ownedId: props.companyId ?? undefined,
          companyId: shareholder.company?.id,
          personId: shareholder.person?.id,
          shares: shareholder.shares,
        };

        selected.value = shareholder;
        upsertDialog.value = true;
      },
      close,
      nameOf: (shareholder: ShareholderFragment) => {
        return shareholder.person
          ? shareholder.person.name
          : shareholder.company
          ? shareholder.company.name
          : shareholder.owned
          ? shareholder.owned.name
          : '';
      },
      rules: {
        shares: (availableShares: number) => {
          const totalAvailable: number = (selected.value?.shares || 0.0) + availableShares;

          return {
            max: totalAvailable.toFixed(2),
            rule: (value: number) => {
              return value > 0 && value <= totalAvailable;
            },
          };
        },
      },
      upsertDialog,
      upsertModel,
      valid: ref<boolean>(false),
    };
  },
});
