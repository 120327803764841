






import { defineComponent, onMounted, onUnmounted, PropType, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { CompanyFragment, CompanyLegalStatus, CompanyStatus, ListCompanyParams } from '@/graphql';

export default defineComponent({
  props: {
    mergedIntoId: {
      type: String,
      required: false,
      default: null,
    },
    take: Number,
    skip: Number,
    status: String as PropType<CompanyStatus>,
    legalStatus: String as PropType<CompanyLegalStatus>,
  },
  setup(props, { root, emit }) {
    const { loadCompanies } = useActions(['loadCompanies']);

    const companies = ref<CompanyFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { mergedIntoId, take, skip, status, legalStatus } = props;

      loading.value = true;

      companies.value = await loadCompanies({
        mergedIntoId,
        take,
        skip,
        status,
        legalStatus,
      } as ListCompanyParams);

      loading.value = false;

      emit('loaded');
    };

    watch(
      [() => props.mergedIntoId, () => props.skip, () => props.take, () => props.status, () => props.legalStatus],
      fetchData,
    );

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteCompany', 'upsertCompany'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      companies,
      loading,
    };
  },
});
