




























import { defineComponent, PropType } from '@vue/composition-api';
import { AuthorityFragment, Role } from '@/graphql';
import RepresentationTypeChip from '@/components/Chips/RepresentationTypeChip.vue';
import { RawLocation } from 'vue-router';
import { roles } from '@/constants';

export type AuthorityLinkType = 'company' | 'person';

export default defineComponent({
  components: { RepresentationTypeChip },
  props: {
    items: {
      type: Array as PropType<AuthorityFragment[]>,
      required: true,
    },
    title: {
      type: String as PropType<AuthorityLinkType>,
      required: true,
    },
    emptyText: {
      type: String,
      required: false,
      default: () => 'Keine Daten',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    linked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    return {
      nameOf: (authority: AuthorityFragment, link: AuthorityLinkType): string => {
        const model: AuthorityFragment['company'] | AuthorityFragment['person'] | undefined | null = authority[link];
        return model?.name || '';
      },
      roleName: (role?: Role | null) => {
        return role ? roles[role] || 'Unbekannt' : '';
      },
      linkOf: (authority: AuthorityFragment): RawLocation | null => {
        const model: AuthorityFragment['company'] | AuthorityFragment['person'] | undefined | null =
          authority[props.title];

        if (!model) {
          return null;
        }

        switch (props.title) {
          case 'company':
            return { name: 'companies', params: { id: model!.id } };
          case 'person':
            return { name: 'persons', params: { id: model!.id } };
        }

        return null;
      },
    };
  },
});
