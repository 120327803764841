






import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { BankAccountFragment, ListBankAccountParams } from '@/graphql';

export default defineComponent({
  props: {
    take: Number,
    companyId: String,
  },
  setup(props, { emit, root }) {
    const { loadBankAccounts } = useActions(['loadBankAccounts']);

    const bankAccounts = ref<BankAccountFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { take, companyId } = props;

      loading.value = true;

      bankAccounts.value = await loadBankAccounts({
        companyId,
        take,
      } as ListBankAccountParams);

      loading.value = false;

      emit('loaded');
    };

    watch([() => props.take, () => props.companyId], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteBankAccount', 'upsertBankAccount'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      bankAccounts,
      loading,
    };
  },
});
