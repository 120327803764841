













import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { CompanyFragment } from '@/graphql';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<CompanyFragment>,
      required: false,
    },
    companies: {
      type: Array as PropType<CompanyFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value);

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
    );

    return {
      model,
      input: (company: CompanyFragment) => {
        emit('input', company);
      },
    };
  },
});
