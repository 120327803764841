


























































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import ContactList from '@/components/Lists/ContactList.vue';
import { BankAccountFragment } from '@/graphql';
import ContactForm from '@/components/Forms/ContactForm.vue';
import BankAccountForm from '@/components/Forms/BankAccountForm.vue';
import BankAccountList from '@/components/Lists/BankAccountList.vue';
import BankAccountDeleteProvider from '@/providers/BankAccountDeleteProvider.vue';
import BankMandatesForm from '@/components/Forms/BankMandatesForm.vue';
import BankMandateListProvider from '@/providers/BankMandateListProvider.vue';
import PersonListProvider from '@/providers/PersonListProvider.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import BankMandateUpsertProvider from '@/providers/BankMandateUpsertProvider.vue';
import BankAccountUpsertProvider from '@/providers/BankAccountUpsertProvider.vue';

export default defineComponent({
  components: {
    BankAccountUpsertProvider,
    BankMandateUpsertProvider,
    Tooltip,
    PersonListProvider,
    BankMandateListProvider,
    BankMandatesForm,
    BankAccountDeleteProvider,
    BankAccountList,
    BankAccountForm,
    ContactForm,
    ContactList,
    Toolbar,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    bankAccounts: {
      type: Array as PropType<BankAccountFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const selected = ref<BankAccountFragment | null>(null);
    const selectedForMandates = ref<BankAccountFragment | null>(null);

    return {
      select: (bankAccount: BankAccountFragment) => {
        selected.value = bankAccount;
      },
      selectForMandates: (bankAccount: BankAccountFragment) => {
        selectedForMandates.value = bankAccount;
      },
      unselect: () => {
        selected.value = null;
      },
      unselectForMandates: () => {
        selectedForMandates.value = null;
      },
      selected,
      selectedForMandates,
      created: (bankAccount: BankAccountFragment) => {
        selected.value = bankAccount;
      },
    };
  },
});
