








































import { defineComponent, PropType } from '@vue/composition-api';
import { CompanyFragment } from '@/graphql';
import CompanyStatusChip from '@/components/Chips/CompanyStatusChip.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import CompanyLegalStatusChip from '@/components/Chips/CompanyLegalStatusChip.vue';

export default defineComponent({
  components: { CompanyLegalStatusChip, Tooltip, CompanyStatusChip },
  props: {
    items: {
      type: Array as PropType<CompanyFragment[]>,
      required: true,
    },
    linked: {
      type: Boolean,
      required: false,
      default: false,
    },
    withLegalStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    withStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
