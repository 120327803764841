






























import { defineComponent, PropType } from '@vue/composition-api';
import { PlannedMeasureFragment } from '@/graphql';
import Tooltip from '@/components/Tooltip/Tooltip.vue';

export default defineComponent({
  components: { Tooltip },
  props: {
    items: {
      type: Array as PropType<PlannedMeasureFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    linked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
