

























































































































import { defineComponent, PropType } from '@vue/composition-api';
import { Person } from '@/graphql';
import FieldList from '@/components/Lists/FieldList.vue';
import Swap from '@/components/Swap/Swap.vue';
import EditableCard from '@/components/Cards/EditableCard.vue';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import ContactList from '@/components/Lists/ContactList.vue';
import ContactsCard from '@/components/Cards/ContactsCard.vue';
import PlannedMeasureSourceChip from '@/components/Chips/PlannedMeasureSourceChip.vue';
import AuthorityListProvider from '@/providers/AuthorityListProvider.vue';
import AuthorityList from '@/components/Lists/AuthorityList.vue';
import PersonForm from '@/components/Forms/PersonForm.vue';
import PersonDeleteProvider from '@/providers/PersonDeleteProvider.vue';
import DeleteDialog from '@/components/Dialogs/DeleteDialog.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import PersonUpsertProvider from '@/providers/PersonUpsertProvider.vue';
import { useGetters } from 'vuex-composition-helpers';
import ShareholderListProvider from '@/providers/ShareholderListProvider.vue';
import ShareholdersCard from '@/components/Cards/ShareholdersCard.vue';
import ShareholderList from '@/components/Lists/ShareholderList.vue';
import BankMandateListProvider from '@/providers/BankMandateListProvider.vue';
import BankMandateList from '@/components/Lists/BankMandateList.vue';

export default defineComponent({
  components: {
    BankMandateList,
    BankMandateListProvider,
    ShareholderList,
    ShareholdersCard,
    ShareholderListProvider,
    PersonUpsertProvider,
    Tooltip,
    DeleteDialog,
    PersonDeleteProvider,
    PersonForm,
    AuthorityList,
    AuthorityListProvider,
    PlannedMeasureSourceChip,
    ContactsCard,
    Toolbar,
    EditableCard,
    Swap,
    ContactList,
    FieldList,
  },
  props: {
    person: {
      type: Object as PropType<Person | null>,
      required: false,
      default: null,
    },
    personId: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isAdmin, isManager } = useGetters(['isAdmin', 'isManager']);

    return {
      userHasEditAccess: isAdmin.value || isManager.value,
      userHasAdminAccess: isAdmin.value,
      onClose: () => emit('close'),
    };
  },
});
