















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    fields: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
});
