






import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    param: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const paramValue = ref<string | null>(null);

    const paramValueResolver = (param: string) => {
      paramValue.value = root.$route.params[param] || null;
    };

    onMounted(() => paramValueResolver(props.param));

    watch(
      () => root.$route,
      () => paramValueResolver(props.param),
    );

    watch(
      () => props.param,
      (newValue) => paramValueResolver(newValue),
    );

    return {
      paramValue: paramValue,
    };
  },
});
