































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { AuthorityFragment, CompanyLegalType, Person, UpsertAuthorityInput } from '@/graphql';
import CompanySelect from '@/components/Inputs/CompanySelect.vue';
import PersonSelect from '@/components/Inputs/PersonSelect.vue';
import RoleSelect from '@/components/Inputs/RoleSelect.vue';
import { allowedRoles, representationTypes, roles } from '@/constants';
import DatePicker from '@/components/Inputs/DatePicker.vue';

export type OnSubmitCallback = (data: UpsertAuthorityInput) => Promise<void>;

export default defineComponent({
  components: { DatePicker, RoleSelect, PersonSelect, CompanySelect },
  props: {
    model: {
      type: Object as PropType<UpsertAuthorityInput>,
      required: true,
    },
    authorities: {
      type: Array as PropType<AuthorityFragment[]>,
      required: true,
    },
    onSubmit: {
      type: Function as PropType<OnSubmitCallback>,
      required: true,
    },
    persons: {
      type: Array as PropType<Person[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    legalType: {
      type: String as PropType<CompanyLegalType | null>,
      required: false,
    },
  },
  setup(props) {
    const upsertModel = ref<UpsertAuthorityInput>({ ...props.model });

    const representationItems = Object.entries(representationTypes).map(([key, value]) => {
      return { text: value, value: key };
    });

    watch(
      () => props.model,
      (newValue) => {
        upsertModel.value = { ...newValue };
      },
    );

    const availableRoles = computed(() => {
      let items = Object.values(roles);

      if (props.legalType) {
        items = allowedRoles[props.legalType];
      }

      items = items.filter(
        (roleItem) =>
          !props.authorities.some(({ person, role }) => {
            return person?.id === upsertModel.value.personId && role === roleItem;
          }),
      );

      if (upsertModel.value.role) {
        items.push(upsertModel.value.role);
      }

      return items;
    });

    return {
      upsertModel,
      person: computed(() =>
        upsertModel.value.personId
          ? props.persons.find((person) => person.id === upsertModel.value.personId) ?? null
          : null,
      ),
      availableRoles,
      representationItems,
      submit: async (after: Function[] = []) => {
        await props.onSubmit(upsertModel.value);
        after.forEach((func) => func());
      },
    };
  },
});
