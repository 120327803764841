














import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { BankAccountFragment } from '@/graphql';
import { bankAccountTypes } from '@/constants';

export type OnSubmitCallback = (bankAccount: BankAccountFragment) => Promise<void>;

export default defineComponent({
  props: {
    bankAccount: {
      type: Object as PropType<BankAccountFragment>,
      required: true,
    },
    onSubmit: {
      type: Function as PropType<OnSubmitCallback>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const model = ref<BankAccountFragment>({ ...props.bankAccount });

    const validInput = computed<boolean>(
      () => model.value.bank !== '' && model.value.iban !== '' && model.value.bic !== '',
    );

    const accountTypeItems = Object.entries(bankAccountTypes).map(([key, value]) => {
      return { text: value, value: key };
    });

    return {
      model,
      validInput,
      accountTypeItems,
      submit: async (after: Function[]) => {
        await props.onSubmit(model.value);
        after.forEach((func) => func());
      },
    };
  },
});
