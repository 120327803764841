











import { Person, PersonFragment } from '@/graphql';
import { defineComponent, onMounted, PropType, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    personId: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { loadPerson, upsertPerson } = useActions(['loadPerson', 'upsertPerson']);

    const person = ref<PersonFragment | null>(null);
    const loading = ref<boolean>(false);
    const upserting = ref<boolean>(false);

    const fetchData = async () => {
      if (!props.personId) {
        return;
      }

      loading.value = true;
      person.value = await loadPerson(props.personId);
      loading.value = false;

      emit('loaded');
    };

    watch([() => props.personId], fetchData);

    onMounted(fetchData);

    return {
      person,
      loading,
      upserting,
      upsertPerson: async (model: Person) => {
        upserting.value = true;

        const data: PersonFragment | null = await upsertPerson({ id: model.id || undefined, name: model.name });

        if (data) {
          upserting.value = false;
          person.value = data;
          emit('upserted', data);
        }
      },
    };
  },
});
