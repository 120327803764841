





















import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { CompanyLegalStatus } from '@/graphql';
import { companyLegalStatus } from '@/constants';
import CompanyLegalStatusChip from '@/components/Chips/CompanyLegalStatusChip.vue';

export default defineComponent({
  components: { CompanyLegalStatusChip },
  props: {
    value: {
      type: String as PropType<CompanyLegalStatus | null>,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    hasCompanyDebitAgreement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const model = ref<CompanyLegalStatus | null>(props.value);

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
    );

    return {
      model,
      items: computed<CompanyLegalStatus[]>(() => {
        const list: CompanyLegalStatus[] = Object.keys(companyLegalStatus) as CompanyLegalStatus[];

        // @TODO: specify requirements for status availability

        // if (!props.hasCompanyDebitAgreement) {
        //   list.splice(list.indexOf(CompanyStatus.Unoccupied));
        // }

        // add selected item to list if not included (anymore)
        if (model.value && !list.includes(model.value)) {
          list.push(model.value);
        }

        return list;
      }),
      input: (companyLegalStatus: CompanyLegalStatus) => {
        emit('input', companyLegalStatus);
      },
    };
  },
});
