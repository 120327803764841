






import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { DocumentFragment } from '@/graphql';

export default defineComponent({
  props: {
    companyId: {
      type: String,
    },
    take: {
      type: Number,
    },
    skip: {
      type: Number,
    },
  },
  setup(props, { emit, root }) {
    const { loadDocuments } = useActions(['loadDocuments']);

    const documents = ref<DocumentFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { companyId, skip, take } = props;

      loading.value = true;
      documents.value = await loadDocuments({ companyId, skip, take });
      loading.value = false;

      emit('loaded', documents.value);
    };

    watch([() => props.companyId, () => props.take, () => props.take], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteDocument', 'uploadDocument'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      documents,
      loading,
    };
  },
});
