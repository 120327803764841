




import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Person, Role } from '@/graphql';
import { roles } from '@/constants';

export default defineComponent({
  props: {
    value: {
      type: String as PropType<Role>,
      required: false,
    },
    roles: {
      type: Array as PropType<Role[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value);

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
    );

    const items = computed(() => {
      return props.roles.map((role: Role) => {
        return { text: roles[role], value: role };
      });
    });

    return {
      model,
      items,
      input: (person: Person) => {
        emit('input', person);
      },
    };
  },
});
