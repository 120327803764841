






import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { Contact, ContactFragment, UpsertContactInput } from '@/graphql';

export default defineComponent({
  props: {
    plannedMeasureId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { upsertContact } = useActions(['upsertContact']);
    const upserting = ref<boolean>(false);

    return {
      upserting,
      upsertContact: async (model: Contact) => {
        upserting.value = true;

        const data: ContactFragment | null = await upsertContact({
          id: model.id ?? undefined,
          name: model.name,
          plannedMeasureId: props.plannedMeasureId,
        } as UpsertContactInput);

        if (data) {
          upserting.value = false;
          emit('upserted', data);
        }
      },
    };
  },
});
