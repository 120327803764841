





import { computed, defineComponent, PropType } from '@vue/composition-api';
import { CompanyStatus } from '@/graphql';
import { companyStatus } from '@/constants';

export default defineComponent({
  props: {
    status: {
      type: String as PropType<CompanyStatus>,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const statusProps: { [key: string]: object } = Object.fromEntries(
      Object.entries(companyStatus).map(([key, value]) => [
        key,
        {
          color: 'blue lighten-1',
          textColor: 'white',
          label: value,
        },
      ]),
    );

    return {
      state: computed(() => {
        return (
          statusProps[props.status] || {
            color: 'grey',
            textColor: 'white',
            label: props.status || 'Unbekannt',
          }
        );
      }),
    };
  },
});
