






import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { BankAccountFragment, BankAccountType, UpsertBankAccountInput } from '@/graphql';

export default defineComponent({
  props: {
    companyId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { upsertBankAccount } = useActions(['upsertBankAccount']);
    const upserting = ref<boolean>(false);

    return {
      upserting,
      upsert: async (data: BankAccountFragment) => {
        upserting.value = true;

        const result: BankAccountFragment | null = await upsertBankAccount({
          id: data.id ?? undefined,
          accountType: data.accountType ?? BankAccountType.Business,
          bank: data.bank ?? '',
          iban: data.iban ?? '',
          bic: data.bic ?? '',
          companyId: props.companyId ?? data.company?.id ?? undefined,
        } as UpsertBankAccountInput);

        if (result) {
          upserting.value = false;
          emit('upserted', result);
        }
      },
    };
  },
});
