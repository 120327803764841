import { AUTH_TYPE } from 'aws-appsync/lib/client';
import { AuthOptions } from 'aws-appsync-auth-link';
import store from '@/store';

export default {
  url: process.env.VUE_APP_AWS_APPSYNC_ENDPOINT!,
  region: process.env.VUE_APP_AWS_APPSYNC_REGION!,
  auth:
    process.env.VUE_APP_AWS_APPSYNC_AUTH_TYPE === 'OPENID_CONNECT'
      ? ({ type: AUTH_TYPE.OPENID_CONNECT, jwtToken: async () => await store.getters.oidcIdToken } as AuthOptions)
      : ({ type: AUTH_TYPE.API_KEY, apiKey: process.env.VUE_APP_AWS_APPSYNC_API_KEY! } as AuthOptions),
};
