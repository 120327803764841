

















































import { defineComponent } from '@vue/composition-api';
import Search from '@/components/Inputs/Search.vue';
import Layout from '@/components/Layout.vue';
import CompanyList from '@/components/Lists/CompanyList.vue';
import SearchProvider from '@/providers/SearchProvider.vue';
import CompanyListProvider from '@/providers/CompanyListProvider.vue';
import DashboardProvider from '@/providers/DashboardProvider.vue';
import { CompanyStatus } from '@/graphql';

export default defineComponent({
  components: { SearchProvider, CompanyList, Search, Layout, CompanyListProvider, DashboardProvider },
  setup() {
    const status: CompanyStatus = CompanyStatus.Usable;
    return { status };
  },
});
