




























import { computed, defineComponent, PropType, ref, unref } from '@vue/composition-api';
import { BankMandateFragment, BankMandateType, PersonFragment } from '@/graphql';
import PersonSelect from '@/components/Inputs/PersonSelect.vue';
import BankMandateList from '@/components/Lists/BankMandateList.vue';
import bankMandateTypes from '@/constants/bank-mandate-types';

export type OnBankMandateAddCallback = (person: PersonFragment, mandateType: BankMandateType) => Promise<void>;

export default defineComponent({
  components: { BankMandateList, PersonSelect },
  props: {
    bankAccountId: {
      type: String,
      required: true,
    },
    bankMandates: {
      type: Array as PropType<BankMandateFragment[]>,
      required: true,
    },
    persons: {
      type: Array as PropType<PersonFragment[]>,
      required: true,
    },
    onBankMandateAdd: {
      type: Function as PropType<OnBankMandateAddCallback>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const selectedPerson = ref<PersonFragment | null>(null);
    const mandateType = ref<BankMandateType>(BankMandateType.E);

    return {
      selectedPerson,
      mandateType,
      bankMandateTypes: computed(() => Object.entries(bankMandateTypes).map(([value, text]) => ({ text, value }))),
      availablePersons: computed<PersonFragment[]>(() => {
        return (props.persons as PersonFragment[]).filter((person) => {
          return (
            (props.bankMandates as BankMandateFragment[]).findIndex(
              (bankMandate) => bankMandate.person?.id === person.id,
            ) === -1
          );
        });
      }),
      submit: async (after: Function[]) => {
        const person: PersonFragment | null = unref(selectedPerson);

        if (person) {
          await props.onBankMandateAdd(person, unref(mandateType));
          selectedPerson.value = null;
          after.forEach((func) => func());
        }
      },
    };
  },
});
