<template>
  <v-container class="fill-height">
    <v-row class="mb-10" justify="center" no-gutters>
      <v-col cols="auto" class="text-center">
        <v-progress-circular class="mb-10" size="128" width="8" color="secondary" indeterminate></v-progress-circular>
        <p class="text-h6 text-secondary">Processing Login Information</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, onMounted } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  setup(_, ctx) {
    const router = ctx.root.$router;
    const { oidcSignInCallback } = useActions(['oidcSignInCallback']);

    onMounted(() => {
      oidcSignInCallback()
        .then((redirectPath) => {
          router.push(redirectPath);
        })
        .catch((error) => {
          console.log(error);
          router.push('/oidc-error');
        });
    });
  },
});
</script>

<style scoped></style>
