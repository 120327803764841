





import { computed, defineComponent, PropType } from '@vue/composition-api';
import { CompanyUsage } from '@/graphql';
import { companyUsages } from '@/constants';

export default defineComponent({
  props: {
    usage: {
      type: String as PropType<CompanyUsage | null>,
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    text: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const usageProps: { [key: string]: object } = Object.fromEntries(
      Object.entries(companyUsages).map(([key, value]) => [
        key,
        {
          color: 'blue lighten-1',
          textColor: 'white',
          label: value,
        },
      ]),
    );

    return {
      state: computed(() => {
        return props.usage
          ? usageProps[props.usage] || {
              color: 'grey',
              textColor: 'white',
              label: props.usage || 'Unbekannt',
            }
          : null;
      }),
    };
  },
});
