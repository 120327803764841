export const oidcSettings = {
  authority: 'https://exporo.onelogin.com/oidc/2',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  clientId: process.env.VUE_APP_ONELOGIN_CLIENT_ID!,
  redirectUri: window.location.origin + '/oidc-callback',
  responseType: 'code',
  scope: 'openid profile groups params',
  filterProtocolClaims: true,
  loadUserInfo: true,
  silentRedirectUri: window.location.origin + '/oidc-silent-renew',
  automaticSilentRenew: true,
};
