




























































































































































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import DeleteDialog from '@/components/Dialogs/DeleteDialog.vue';
import MergeDialog from '@/components/Dialogs/MergeDialog.vue';
import CompanyMergeProvider from '@/providers/CompanyMergeProvider.vue';
import DocumentList from '@/components/Lists/DocumentList.vue';
import CompanyDeleteProvider from '@/providers/CompanyDeleteProvider.vue';
import { CompanyFragment, CompanyLegalStatus, Document, DocumentType } from '@/graphql';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import CompanyForm from '@/components/Forms/CompanyForm.vue';
import FieldList from '@/components/Lists/FieldList.vue';
import CompanyLegalTypeChip from '@/components/Chips/CompanyLegalTypeChip.vue';
import CompanyStatusChip from '@/components/Chips/CompanyStatusChip.vue';
import AuthorityListProvider from '@/providers/AuthorityListProvider.vue';
import AuthoritiesCard from '@/components/Cards/AuthoritiesCard.vue';
import BankAccountListProvider from '@/providers/BankAccountListProvider.vue';
import BankAccountsCard from '@/components/Cards/BankAccountsCard.vue';
import DocumentListProvider from '@/providers/DocumentListProvider.vue';
import DocumentsCard from '@/components/Cards/DocumentsCard.vue';
import PlannedMeasureListProvider from '@/providers/PlannedMeasureListProvider.vue';
import PlannedMeasureList from '@/components/Lists/PlannedMeasureList.vue';
import CompanyListProvider from '@/providers/CompanyListProvider.vue';
import CompanyList from '@/components/Lists/CompanyList.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import ShareholderListProvider from '@/providers/ShareholderListProvider.vue';
import ShareholdersCard from '@/components/Cards/ShareholdersCard.vue';
import CompanyUsageChip from '@/components/Chips/CompanyUsageChip.vue';
import CompanyUpsertProvider from '@/providers/CompanyUpsertProvider.vue';
import { useGetters } from 'vuex-composition-helpers';
import CompanyLegalStatusChip from '@/components/Chips/CompanyLegalStatusChip.vue';

export default defineComponent({
  components: {
    CompanyLegalStatusChip,
    CompanyUpsertProvider,
    CompanyUsageChip,
    ShareholdersCard,
    ShareholderListProvider,
    Tooltip,
    CompanyList,
    CompanyListProvider,
    PlannedMeasureList,
    PlannedMeasureListProvider,
    DocumentsCard,
    DocumentListProvider,
    BankAccountsCard,
    BankAccountListProvider,
    AuthoritiesCard,
    AuthorityListProvider,
    CompanyStatusChip,
    CompanyLegalTypeChip,
    FieldList,
    CompanyForm,
    Toolbar,
    CompanyDeleteProvider,
    DocumentList,
    CompanyMergeProvider,
    MergeDialog,
    DeleteDialog,
  },
  props: {
    company: {
      type: Object as PropType<CompanyFragment | null>,
      required: false,
      default: null,
    },
    companyId: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const companyDocuments = ref<Document[]>([]);
    const { isAdmin, isManager } = useGetters(['isAdmin', 'isManager']);

    return {
      isCompanyMerged: computed(() => {
        return props.company ? props.company.legalStatus === CompanyLegalStatus.Liquidated : false;
      }),
      userHasEditAccess: isAdmin.value || isManager.value,
      userHasAdminAccess: isAdmin.value,
      onClose: () => emit('close'),
      companyDocuments,
      onDocumentsLoaded: (documents: Document[]) => {
        companyDocuments.value = documents;
      },
      hasCompanyDebitAgreement: computed(() =>
        companyDocuments.value.some((value: Document) => value.documentType === DocumentType.DebitAgreement),
      ),
    };
  },
});
