










































import { defineComponent, ref, PropType, computed } from '@vue/composition-api';

type Filterable = {
  text: string;
  value: unknown;
};

export default defineComponent({
  props: {
    filters: {
      type: Array as PropType<Filterable[]>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array as PropType<unknown[]>,
      required: false,
      default: [],
    },
  },
  setup(props, { emit }) {
    const indices = props.value.map((value) => props.filters.findIndex((filter) => filter.value === value));
    const model = ref<number[]>(indices);

    const onChange = (indices: number[]) => {
      const values = indices.map((index) => props.filters[index].value);
      emit('input', values);
    };

    const onReset = () => {
      model.value = [];
      emit('input', model.value);
    };

    return {
      hasActiveFilters: computed(() => model.value?.length > 0),
      model,
      onChange,
      onReset,
    };
  },
});
