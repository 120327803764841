



































import { defineComponent, PropType, ref } from '@vue/composition-api';
import CompanyListProvider from '@/providers/CompanyListProvider.vue';
import CompanySelect from '@/components/Inputs/CompanySelect.vue';
import { CompanyFragment } from '@/graphql';

export type OnMergeCallback = (data: CompanyFragment) => Promise<void>;

export default defineComponent({
  components: { CompanySelect, CompanyListProvider },
  props: {
    name: {
      type: String,
      required: true,
    },
    onMerge: {
      type: Function as PropType<OnMergeCallback>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const open = ref<boolean>(false);
    const selectedCompany = ref<CompanyFragment | null>();

    return {
      open,
      selectedCompany,
      onAbort: () => (open.value = false),
      onSubmit: () => {
        if (selectedCompany.value != null) {
          props.onMerge(selectedCompany.value);
        }
      },
    };
  },
});
