






import { PersonFragment } from '@/graphql';
import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    take: {
      type: Number,
    },
    skip: {
      type: Number,
    },
  },
  setup(props, { emit, root }) {
    const { loadPersons } = useActions(['loadPersons']);

    const persons = ref<PersonFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { take, skip } = props;

      loading.value = true;
      persons.value = await loadPersons({
        take,
        skip,
      });
      loading.value = false;

      emit('loaded');
    };

    watch([() => props.skip, () => props.take], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deletePerson', 'upsertPerson'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      persons,
      loading,
    };
  },
});
