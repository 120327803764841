import { ActionContext, Module } from 'vuex';
import {
  DeleteAuthorityDocument,
  DeleteAuthorityMutation,
  DeleteAuthorityMutationVariables,
  ListAuthoritiesDocument,
  ListAuthoritiesQuery,
  ListAuthoritiesQueryVariables,
  ListAuthorityParams,
  UpsertAuthorityDocument,
  UpsertAuthorityInput,
  UpsertAuthorityMutation,
  UpsertAuthorityMutationVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type AuthoritiesState = {};

type Context = ActionContext<AuthoritiesState, RootState>;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async loadAuthorities({ commit }: Context, listParams: ListAuthorityParams = {}) {
      const { data } = await apolloClient.query<ListAuthoritiesQuery, ListAuthoritiesQueryVariables>({
        query: ListAuthoritiesDocument,
        variables: { listParams },
      });

      return data.listAuthorities || [];
    },

    async upsertAuthority({ commit }: Context, upsertAuthorityInput: UpsertAuthorityInput) {
      const { data } = await apolloClient.mutate<UpsertAuthorityMutation, UpsertAuthorityMutationVariables>({
        mutation: UpsertAuthorityDocument,
        variables: { upsertAuthorityInput },
      });

      return data?.upsertAuthority.data ?? null;
    },

    async deleteAuthority({ commit }: Context, authorityId: string) {
      return apolloClient.mutate<DeleteAuthorityMutation, DeleteAuthorityMutationVariables>({
        mutation: DeleteAuthorityDocument,
        variables: { authorityId },
      });
    },
  },
} as Module<AuthoritiesState, RootState>;
