










import { defineComponent, PropType } from '@vue/composition-api';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import AuthorityList from '@/components/Lists/AuthorityList.vue';
import DocumentList from '@/components/Lists/DocumentList.vue';
import UploadForm from '@/components/Forms/UploadForm.vue';

export default defineComponent({
  components: {
    UploadForm,
    DocumentList,
    AuthorityList,
    Toolbar,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    documents: {
      type: Array as PropType<Document[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
