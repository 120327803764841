






import { computed, defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { debounce } from 'debounce';
import { CompanyFragment, PersonFragment, PlannedMeasureFragment } from '@/graphql';

export default defineComponent({
  setup(props, { emit }) {
    const { search } = useActions(['search']);

    const loading = ref<boolean>(false);

    const searchResults = ref<{
      searchCompanies: CompanyFragment[];
      searchPersons: PersonFragment[];
      searchPlannedMeasures: PlannedMeasureFragment[];
    } | null>(null);

    const debouncedSearch = debounce(async (searchQuery: string) => {
      loading.value = true;
      searchResults.value = await search(searchQuery);
      loading.value = false;
      emit('searched');
    }, 250);

    return {
      results: computed(() => {
        return searchResults.value
          ? [
              ...searchResults.value.searchCompanies,
              ...searchResults.value.searchPersons,
              ...searchResults.value.searchPlannedMeasures,
            ]
          : [];
      }),
      loading,
      search: async (searchQuery: string) => {
        if (searchQuery.length === 0) {
          return;
        }

        return debouncedSearch(searchQuery);
      },
    };
  },
});
