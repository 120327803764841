






import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { BankMandate, BankMandateFragment, UpsertBankMandateInput } from '@/graphql';

export default defineComponent({
  setup(props, { emit }) {
    const upserting = ref<boolean>(false);
    const { upsertBankMandate } = useActions(['upsertBankMandate']);

    return {
      upserting,
      upsertBankMandate: async (bankMandate: BankMandate) => {
        upserting.value = true;

        const data: BankMandateFragment | null = await upsertBankMandate({
          id: bankMandate.id,
          personId: bankMandate.person?.id ?? undefined,
          bankAccountId: bankMandate.bankAccount?.id ?? undefined,
          mandateType: bankMandate.mandateType ?? undefined,
        } as UpsertBankMandateInput);

        if (data) {
          upserting.value = false;
          emit('upserting', data.id);
        }
      },
    };
  },
});
