








import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  setup(props, { emit }) {
    const deleting = ref<boolean>(false);
    const { deleteBankAccount } = useActions(['deleteBankAccount']);

    return {
      deleting,
      deleteBankAccount: async (bankAccountId: string) => {
        deleting.value = true;

        const deletedId: string | null = await deleteBankAccount(bankAccountId);

        if (deletedId) {
          deleting.value = false;
          emit('deleted', deletedId);
        }
      },
    };
  },
});
