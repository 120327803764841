



































import { defineComponent, PropType } from '@vue/composition-api';
import { DocumentFragment } from '@/graphql';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import DocumentDeleteProvider from '@/providers/DocumentDeleteProvider.vue';
import DocumentDownloadProvider from '@/providers/DocumentDownloadProvider.vue';
import { documentTypes } from '@/constants';

export default defineComponent({
  components: { DocumentDownloadProvider, DocumentDeleteProvider, Tooltip },
  props: {
    items: {
      type: Array as PropType<DocumentFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    downloadable: {
      type: Boolean,
      required: false,
      default: false,
    },
    removable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const documentTypeProps: { [key: string]: string } = Object.fromEntries(
      Object.entries(documentTypes).map(([key, value]) => [key, value]),
    );

    return {
      documentTypeProps,
    };
  },
});
