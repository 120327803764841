






import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {},
  setup(props, { emit }) {
    const { deletePlannedMeasure } = useActions(['deletePlannedMeasure']);
    const deleting = ref<boolean>(false);

    return {
      deleting,
      deletePlannedMeasure: async (plannedMeasureId: string) => {
        deleting.value = true;
        await deletePlannedMeasure(plannedMeasureId);
        deleting.value = false;

        emit('deleted');
      },
    };
  },
});
