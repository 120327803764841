
























import { defineComponent, PropType } from '@vue/composition-api';
import { ShareholderFragment } from '@/graphql';
import RepresentationTypeChip from '@/components/Chips/RepresentationTypeChip.vue';
import { RawLocation } from 'vue-router';

export default defineComponent({
  components: { RepresentationTypeChip },
  props: {
    items: {
      type: Array as PropType<ShareholderFragment[]>,
      required: true,
    },
    emptyText: {
      type: String,
      required: false,
      default: 'Keine Daten',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    companyId: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    return {
      nameOf: (shareholder: ShareholderFragment) => {
        const isHolding = shareholder.ownedId && shareholder.ownedId !== props.companyId;

        if (isHolding) {
          return shareholder.owned?.name;
        }

        return shareholder.person ? shareholder.person.name : shareholder.company ? shareholder.company.name : '';
      },
      linkOf: (shareholder: ShareholderFragment): RawLocation | null => {
        const isHolding = shareholder.ownedId && shareholder.ownedId !== props.companyId;

        if (isHolding) {
          return { name: 'companies', params: { id: shareholder.ownedId! } };
        } else if (shareholder.companyId) {
          return { name: 'companies', params: { id: shareholder.companyId } };
        } else if (shareholder.personId) {
          return { name: 'persons', params: { id: shareholder.personId } };
        }

        return null;
      },
    };
  },
});
