import { render, staticRenderFns } from "./CompanyStatusSelect.vue?vue&type=template&id=460bbf88&scoped=true&"
import script from "./CompanyStatusSelect.vue?vue&type=script&lang=ts&"
export * from "./CompanyStatusSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460bbf88",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
