






import { PlannedMeasureFragment } from '@/graphql';
import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  props: {
    companyId: {
      type: String,
    },
    take: {
      type: Number,
    },
    skip: {
      type: Number,
    },
  },
  setup(props, { emit, root }) {
    const { loadPlannedMeasures } = useActions(['loadPlannedMeasures']);

    const plannedMeasures = ref<PlannedMeasureFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { companyId, skip, take } = props;

      loading.value = true;

      plannedMeasures.value = await loadPlannedMeasures({
        companyId,
        skip,
        take,
      });

      loading.value = false;

      emit('loaded');
    };

    watch([() => props.companyId, () => props.skip, () => props.take], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deletePlannedMeasure', 'upsertPlannedMeasure'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      plannedMeasures,
      loading,
    };
  },
});
