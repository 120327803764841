import { CompanyLegalType, Role } from '@/graphql';

const allowedRoles: { [K in CompanyLegalType]: Role[] } = {
  [CompanyLegalType.Gbr]: [Role.Ceo, Role.Proxy, Role.PowerOfAttorney],
  [CompanyLegalType.Ug]: [Role.Ceo, Role.Proxy, Role.PowerOfAttorney],
  [CompanyLegalType.Gmbh]: [Role.Ceo, Role.Proxy, Role.PowerOfAttorney],
  [CompanyLegalType.Gmbhcokg]: [Role.LimitedPartner, Role.Complementary, Role.Ceo, Role.Proxy, Role.PowerOfAttorney],
  [CompanyLegalType.Ag]: [Role.AdministrativeBoard, Role.Chairman, Role.Proxy, Role.PowerOfAttorney],
  [CompanyLegalType.Sarl]: [Role.AdministrativeBoard, Role.Chairman, Role.Proxy, Role.PowerOfAttorney],
};

export default allowedRoles;
