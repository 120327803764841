




























import { defineComponent } from '@vue/composition-api';
import Swap from '@/components/Swap/Swap.vue';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';

export default defineComponent({
  components: { Tooltip, Toolbar, Swap },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
