






import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  setup(props, { emit }) {
    const { downloadDocument } = useActions(['downloadDocument']);
    const downloading = ref<boolean>(false);

    return {
      downloading,
      downloadDocument: async (documentId: string) => {
        downloading.value = true;
        await downloadDocument({ documentId });
        downloading.value = false;

        emit('downloaded');
      },
    };
  },
});
