import { render, staticRenderFns } from "./CompanyProvider.vue?vue&type=template&id=2dfd7eeb&scoped=true&"
import script from "./CompanyProvider.vue?vue&type=script&lang=ts&"
export * from "./CompanyProvider.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dfd7eeb",
  null
  
)

export default component.exports