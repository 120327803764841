<template>
  <v-container class="fill-height">
    <v-row class="mb-10" justify="center" no-gutters>
      <v-col cols="auto" class="text-center">
        <v-progress-circular class="mb-10" size="128" width="8" color="secondary" indeterminate></v-progress-circular>
        <p class="text-h6 text-secondary">Abmeldung</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, onMounted } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  setup(_, ctx) {
    const router = ctx.root.$router;
    const { signOutOidcSilent } = useActions(['signOutOidcSilent']);

    onMounted(() => {
      signOutOidcSilent().then(() => {
        router.push({ name: 'oidc-logout' });
      });
    });
  },
});
</script>

<style scoped></style>
