var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('swap',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var toggle = ref.toggle;
var active = ref.active;
return [_c('Toolbar',{staticClass:"sticky-header",attrs:{"dense":"","loading":_vm.loading,"title":_vm.title}},[(active)?_vm._t("save",null,{"toggle":toggle}):_vm._e(),(active)?_c('Tooltip',{attrs:{"text":"Schließen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":toggle}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)}):(_vm.editable)?_c('Tooltip',{attrs:{"text":"Bearbeiten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":toggle}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}):_vm._e()],2)]}},{key:"active",fn:function(ref){
var toggle = ref.toggle;
return [_vm._t("active",null,{"toggle":toggle})]}},{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_vm._t("default",null,{"toggle":toggle})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }