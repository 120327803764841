var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('Toolbar',{staticClass:"sticky-header",attrs:{"dense":"","loading":_vm.loading,"title":"Ansprechpartner"}},[_c('ContactUpsertProvider',{attrs:{"planned-measure-id":_vm.plannedMeasureId},on:{"upserted":_vm.created},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upsertContact = ref.upsertContact;
var upserting = ref.upserting;
return [(_vm.editable)?_c('Tooltip',{attrs:{"text":"Neuer Kontakt"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-right":"-12px"},attrs:{"icon":"","loading":upserting},on:{"click":function($event){return upsertContact({ name: 'Neuer Ansprechpartner' })}}},on),[_c('v-icon',[_vm._v("mdi-account-plus")])],1)]}}],null,true)}):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"value":!!_vm.selected,"max-width":400,"persistent":""}},[_c('ContactUpsertProvider',{attrs:{"planned-measure-id":_vm.plannedMeasureId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upsertContact = ref.upsertContact;
var upserting = ref.upserting;
return [(_vm.selected)?_c('v-card',{attrs:{"disabled":upserting,"loading":upserting}},[_c('v-card-title',[_vm._v(_vm._s(_vm.selected.name))]),_c('v-card-text',[_c('ContactForm',{attrs:{"contact":_vm.selected,"disabled":upserting,"on-submit":upsertContact},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var submit = ref.submit;
return [_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.unselect}},[_vm._v("Abbrechen")])],1),_c('v-col',[_c('v-btn',{attrs:{"text":"","block":"","loading":upserting},on:{"click":function($event){return submit([_vm.unselect])}}},[_vm._v("Speichern")])],1)],1)]}}],null,true)})],1)],1):_vm._e()]}}])})],1),_c('ContactList',{attrs:{"items":_vm.contacts,"loading":_vm.loading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
return [_c('ContactDeleteProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var deleteContact = ref.deleteContact;
var deleting = ref.deleting;
return [_c('Tooltip',{attrs:{"text":"Bearbeiten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":deleting},on:{"click":function($event){return _vm.select(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('Tooltip',{attrs:{"text":"Löschen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":deleting},on:{"click":function($event){return deleteContact(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }