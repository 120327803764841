

































































































import { defineComponent, PropType } from '@vue/composition-api';
import { PlannedMeasureFragment } from '@/graphql';
import FieldList from '@/components/Lists/FieldList.vue';
import Swap from '@/components/Swap/Swap.vue';
import PlannedMeasureForm from '@/components/Forms/PlannedMeasureForm.vue';
import EditableCard from '@/components/Cards/EditableCard.vue';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import ContactListProvider from '@/providers/ContactListProvider.vue';
import ContactList from '@/components/Lists/ContactList.vue';
import ContactsCard from '@/components/Cards/ContactsCard.vue';
import PlannedMeasureSourceChip from '@/components/Chips/PlannedMeasureSourceChip.vue';
import PlannedMeasureDeleteProvider from '@/providers/PlannedMeasureDeleteProvider.vue';
import DeleteDialog from '@/components/Dialogs/DeleteDialog.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import PlannedMeasureUpsertProvider from '@/providers/PlannedMeasureUpsertProvider.vue';
import { useGetters } from 'vuex-composition-helpers';

export default defineComponent({
  components: {
    PlannedMeasureUpsertProvider,
    Tooltip,
    DeleteDialog,
    PlannedMeasureDeleteProvider,
    PlannedMeasureSourceChip,
    ContactsCard,
    ContactListProvider,
    Toolbar,
    EditableCard,
    PlannedMeasureForm,
    Swap,
    ContactList,
    FieldList,
  },
  props: {
    plannedMeasure: {
      type: Object as PropType<PlannedMeasureFragment | null>,
      required: false,
      default: null,
    },
    plannedMeasureId: {
      type: String,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { isAdmin, isManager } = useGetters(['isAdmin', 'isManager']);

    return {
      userHasEditAccess: isAdmin.value || isManager.value,
      userHasAdminAccess: isAdmin.value,
      onClose: () => emit('close'),
    };
  },
});
