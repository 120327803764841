






import { defineComponent } from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { Company, CompanyLegalStatus, UpsertCompanyInput } from '@/graphql';

export default defineComponent({
  props: {
    companyId: {
      type: String,
      required: true,
    },
    companyName: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { updatingCompany } = useGetters(['updatingCompany']);
    const { upsertCompany } = useActions(['upsertCompany']);

    const mergeCompany = async (company: Company) => {
      await upsertCompany({
        id: props.companyId,
        legalStatus: CompanyLegalStatus.Liquidated,
        status: null,
        mergedIntoId: company.id,
        name: props.companyName,
      } as UpsertCompanyInput);

      emit('merged');
    };

    const unmergeCompany = async () => {
      await upsertCompany({
        id: props.companyId,
        legalStatus: CompanyLegalStatus.InLiquidation,
        status: null,
        mergedIntoId: null,
      } as UpsertCompanyInput);

      emit('unmerged');
    };

    return {
      mergeCompany,
      unmergeCompany,
      updatingCompany,
    };
  },
});
