import { CompanyLegalType } from '@/graphql';

export const companyLegalTypes: { [K in CompanyLegalType]: string } = {
  [CompanyLegalType.Gbr]: 'GbR',
  [CompanyLegalType.Ug]: 'UG',
  [CompanyLegalType.Gmbh]: 'GmbH',
  [CompanyLegalType.Gmbhcokg]: 'GmbH & Co. KG',
  [CompanyLegalType.Ag]: 'AG',
  [CompanyLegalType.Sarl]: 'S.a.r.l.',
};

export default companyLegalTypes;
