



























import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import dayjs from 'dayjs';

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    startView: {
      type: String,
      required: false,
      default: 'DAY',
    },
    label: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const menuOpen = ref(false);
    const menuRef = ref();
    const picker = ref();

    const inputDate = computed(() => (props.value ? dayjs(props.value).format('DD.MM.YYYY') : ''));

    const pickerDate = computed(() => (props.value ? dayjs(props.value).format('YYYY-MM-DD') : ''));

    watch(menuOpen, (isOpen) => {
      isOpen && setTimeout(() => (picker.value.activePicker = props.startView));
    });

    const onPickDate = (date: string) => {
      menuOpen.value = false;
      emit('input', new Date(date).toISOString());
    };

    return {
      menuOpen,
      menuRef,
      inputDate,
      picker,
      pickerDate,
      onPickDate,
    };
  },
});
