






import { defineComponent, ref } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';

export default defineComponent({
  setup(props, { emit }) {
    const { deleteDocument } = useActions(['deleteDocument']);
    const deleting = ref<boolean>(false);

    return {
      deleting,
      deleteDocument: async (documentId: string) => {
        deleting.value = true;
        await deleteDocument(documentId);
        deleting.value = false;

        emit('deleted');
      },
    };
  },
});
