















import { defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const active = ref<boolean>(props.value);

    const toggle = () => {
      active.value = !active.value;
      emit('change', active.value);
    };

    watch(
      () => props.value,
      (newValue) => (active.value = newValue),
    );

    return {
      on: { click: toggle },
      toggle,
      active,
    };
  },
});
