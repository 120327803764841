







































































import { defineComponent, ref } from '@vue/composition-api';
import Layout from '@/components/Layout.vue';
import PersonDrawer from '@/components/Drawers/PersonDrawer.vue';
import PersonProvider from '@/providers/PersonProvider.vue';
import PersonListProvider from '@/providers/PersonListProvider.vue';
import RouteAware from '@/components/Route/RouteAware.vue';
import { Person } from '@/graphql';
import PersonUpsertProvider from '@/providers/PersonUpsertProvider.vue';
import { useGetters } from 'vuex-composition-helpers';

export default defineComponent({
  components: {
    PersonUpsertProvider,
    RouteAware,
    PersonProvider,
    PersonListProvider,
    Layout,
    PersonDrawer,
  },
  setup(props, { root }) {
    const search = ref('');
    const { isManager, isAdmin } = useGetters(['isManager', 'isAdmin']);

    return {
      search,
      userHasEditAccess: isManager.value || isAdmin.value,
      onSelect: (person: Person | null) => {
        root.$router.replace({
          name: 'persons',
          params: { id: person?.id || '' },
        });
      },
      onClose: () => {
        root.$router.replace({
          name: 'persons',
        });
      },
    };
  },
});
