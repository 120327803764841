import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';

import { oidcSettings } from '../../oidc';
import documents from '@/store/documents';
import plannedMeasures from '@/store/plannedMeasures';
import companies from '@/store/companies';
import persons from '@/store/persons';
import dashboard from '@/store/dashboard';
import search from '@/store/search';
import authorities from '@/store/authorities';
import bankAccounts from '@/store/bankAccounts';
import contacts from '@/store/contacts';
import bankMandates from '@/store/bankMandates';
import shareholders from '@/store/shareholders';

Vue.use(Vuex);

export type Notification = {
  message: string;
  color?: string;
};

export type RootState = {
  displayNotification: boolean;
  notification: Notification | null;
};

type Context = ActionContext<RootState, RootState>;

const store = new Vuex.Store<RootState>({
  state: {
    displayNotification: false,
    notification: null,
  },
  getters: {
    displayNotification: (state: RootState) => state.displayNotification,
    notification: (state: RootState) => state.notification,
    isAdmin: (state, getters, rootState, rootGetters) => {
      if (rootGetters.oidcUser && 'groups' in rootGetters.oidcUser && Array.isArray(rootGetters.oidcUser.groups)) {
        return rootGetters.oidcUser.groups.includes('smd:admin');
      }

      return false;
    },
    isManager: (state, getters, rootState, rootGetters) => {
      if (rootGetters.oidcUser && 'groups' in rootGetters.oidcUser && Array.isArray(rootGetters.oidcUser.groups)) {
        return rootGetters.oidcUser.groups.includes('smd:manager');
      }

      return false;
    },
  },
  mutations: {
    notify: (state: RootState, value: Notification) => {
      state.displayNotification = false;

      window.setTimeout(() => {
        const displayTime = 5000;

        state.notification = value;
        state.displayNotification = true;

        window.setTimeout(() => {
          if (value.message !== state.notification?.message) {
            return;
          }

          state.displayNotification = false;
          state.notification = null;
        }, displayTime);
      }, 250);
    },
  },
  actions: {
    async notify({ commit, dispatch }: Context, notification: Notification) {
      commit('notify', notification, { root: true });
    },
  },
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings),
    documents,
    dashboard,
    plannedMeasures,
    companies,
    persons,
    search,
    authorities,
    bankAccounts,
    contacts,
    bankMandates,
    shareholders,
  },
});

export const notify = async (notification: Notification) => {
  await store.dispatch('notify', notification, { root: true });
};

export default store;
