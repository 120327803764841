











































































































import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import Layout from '@/components/Layout.vue';
import CompanyDrawer from '@/components/Drawers/CompanyDrawer.vue';
import CompanyListProvider from '@/providers/CompanyListProvider.vue';
import CompanyProvider from '@/providers/CompanyProvider.vue';
import { Company } from '@/graphql';
import RouteAware from '@/components/Route/RouteAware.vue';
import CompanyLegalStatusChip from '@/components/Chips/CompanyLegalStatusChip.vue';
import CompanyLegalTypeChip from '@/components/Chips/CompanyLegalTypeChip.vue';
import CompanyUsageChip from '@/components/Chips/CompanyUsageChip.vue';
import CompanyStatusChip from '@/components/Chips/CompanyStatusChip.vue';
import CompanyUpsertProvider from '@/providers/CompanyUpsertProvider.vue';
import Facet from '@/components/Facet/Facet.vue';
import { companyLegalStatus, companyStatus, companyUsages } from '@/constants';
import { useGetters } from 'vuex-composition-helpers';

export default defineComponent({
  components: {
    CompanyUpsertProvider,
    Facet,
    CompanyStatusChip,
    CompanyUsageChip,
    CompanyLegalTypeChip,
    CompanyLegalStatusChip,
    RouteAware,
    CompanyProvider,
    CompanyListProvider,
    CompanyDrawer,
    Layout,
  },
  setup(props, { root }) {
    const search = ref('');

    const { isManager, isAdmin } = useGetters(['isManager', 'isAdmin']);

    const facets = reactive<{ [key: string]: unknown[] }>({
      usage: [],
      legalStatus: [],
      status: [],
    });

    const filter = (column: string): Function => {
      return (value?: string | null) => {
        return facets[column].length ? facets[column].includes(value) : true;
      };
    };

    return {
      companyUsages: Object.entries(companyUsages).map(([k, v]) => ({ text: v, value: k })),
      companyLegalStatus: Object.entries(companyLegalStatus).map(([k, v]) => ({ text: v, value: k })),
      companyStatus: Object.entries(companyStatus).map(([k, v]) => ({ text: v, value: k })),
      userHasEditAccess: computed(() => isAdmin.value || isManager.value),
      facets,
      search,
      onSelect: (company: Company | null) => {
        root.$router.replace({
          name: 'companies',
          params: { id: company?.id ?? '' },
        });
      },
      filter,
      onClose: () => {
        root.$router.replace({
          name: 'companies',
        });
      },
    };
  },
});
