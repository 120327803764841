

































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import Toolbar from '@/components/Toolbars/Toolbar.vue';
import { AuthorityFragment, CompanyLegalType, UpsertAuthorityInput } from '@/graphql';
import AuthorityList from '@/components/Lists/AuthorityList.vue';
import AuthorityForm from '@/components/Forms/AuthorityForm.vue';
import PersonListProvider from '@/providers/PersonListProvider.vue';
import AuthorityDeleteProvider from '@/providers/AuthorityDeleteProvider.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import AuthorityUpsertProvider from '@/providers/AuthorityUpsertProvider.vue';

export default defineComponent({
  components: {
    AuthorityUpsertProvider,
    Tooltip,
    AuthorityDeleteProvider,
    PersonListProvider,
    AuthorityForm,
    AuthorityList,
    Toolbar,
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    authorities: {
      type: Array as PropType<AuthorityFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    legalType: {
      type: String as PropType<CompanyLegalType>,
      required: true,
    },
  },
  setup(props) {
    const upsertModel = ref<UpsertAuthorityInput | null>(null);

    return {
      upsertModel,
      create: () => {
        upsertModel.value = {
          companyId: props.companyId,
        };
      },
      select: (authority: AuthorityFragment) => {
        upsertModel.value = {
          ...authority,
          companyId: props.companyId,
          personId: authority.person?.id ?? undefined,
        };
      },
      unselect: () => {
        upsertModel.value = null;
      },
      created: (authority: AuthorityFragment) => {
        upsertModel.value = {
          ...authority,
          companyId: props.companyId,
          personId: authority.person?.id ?? undefined,
        };
      },
    };
  },
});
