var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{scopedSlots:_vm._u([{key:"navigation-drawer",fn:function(){return [_c('RouteAware',{attrs:{"param":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var paramValue = ref.paramValue;
return [_c('CompanyProvider',{attrs:{"company-id":paramValue},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var company = ref.company;
var loading = ref.loading;
return [_c('CompanyDrawer',{attrs:{"open":!!paramValue,"company":company,"company-id":paramValue,"loading":loading},on:{"close":_vm.onClose}})]}}],null,true)})]}}])})]},proxy:true}])},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('h1',[_vm._v("Gesellschaften")])])],1),_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(_vm.userHasEditAccess)?_c('CompanyUpsertProvider',{on:{"upserted":function($event){return _vm.onSelect($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertCompany = ref.upsertCompany;
return [_c('v-btn',{attrs:{"loading":upserting,"disabled":upserting,"color":"primary"},on:{"click":function($event){return upsertCompany({ name: 'Neue Gesellschaft' })}}},[_vm._v(" Gesellschaft hinzufügen ")])]}}],null,false,2441281615)}):_vm._e()],1)],1)],1),_c('v-card',[_c('CompanyListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var companies = ref.companies;
var loading = ref.loading;
return [_c('v-data-table',{staticClass:"table--clickable",attrs:{"headers":[
          { text: 'Name', value: 'name' },
          { text: 'Gesellschaftsform', value: 'legalType' },
          { text: 'Gesellschaftstyp', value: 'usage', filter: _vm.filter('usage') },
          { text: 'Allgemeiner Status', value: 'legalStatus', filter: _vm.filter('legalStatus') },
          { text: 'SPV Status', value: 'status', filter: _vm.filter('status') } ],"items":companies,"loading":loading,"search":_vm.search,"sort-by":"name","sort-desc":false},on:{"click:row":_vm.onSelect},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suche","prepend-inner-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"header.legalStatus",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('Facet',{attrs:{"label":header.text,"filters":_vm.companyLegalStatus},model:{value:(_vm.facets.legalStatus),callback:function ($$v) {_vm.$set(_vm.facets, "legalStatus", $$v)},expression:"facets.legalStatus"}})]}},{key:"header.status",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('Facet',{attrs:{"label":header.text,"filters":_vm.companyStatus},model:{value:(_vm.facets.status),callback:function ($$v) {_vm.$set(_vm.facets, "status", $$v)},expression:"facets.status"}})]}},{key:"header.usage",fn:function(ref){
        var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('Facet',{attrs:{"label":header.text,"filters":_vm.companyUsages},model:{value:(_vm.facets.usage),callback:function ($$v) {_vm.$set(_vm.facets, "usage", $$v)},expression:"facets.usage"}})]}},{key:"no-data",fn:function(){return [_vm._v("Keine Daten")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("Keine Treffer für die Suchanfrage")]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"loading":loading,"type":"table-tbody"}})]},proxy:true},{key:"item.legalType",fn:function(ref){
        var item = ref.item;
return [_c('CompanyLegalTypeChip',{attrs:{"small":"","text":"","legal-type":item.legalType || ''}})]}},{key:"item.usage",fn:function(ref){
        var item = ref.item;
return [_c('CompanyUsageChip',{attrs:{"usage":item.usage || '',"small":""}})]}},{key:"item.legalStatus",fn:function(ref){
        var item = ref.item;
return [_c('CompanyLegalStatusChip',{attrs:{"legal-status":item.legalStatus || '',"small":""}})]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('CompanyStatusChip',{directives:[{name:"show",rawName:"v-show",value:(item.status),expression:"item.status"}],attrs:{"status":item.status || '',"small":""}})]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }