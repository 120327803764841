



































import { defineComponent, PropType } from '@vue/composition-api';
import { BankMandateFragment, BankMandateType } from '@/graphql';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import BankMandateDeleteProvider from '@/providers/BankMandateDeleteProvider.vue';
import bankMandateTypes from '@/constants/bank-mandate-types';
import { RawLocation } from 'vue-router';

export default defineComponent({
  components: { BankMandateDeleteProvider, Tooltip },
  props: {
    items: {
      type: Array as PropType<BankMandateFragment[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    removable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup: () => {
    return {
      nameOf: ({ person, bankAccount }: BankMandateFragment) => {
        return person?.name ?? (bankAccount ? `${bankAccount?.bank} - ${bankAccount?.iban}` : '');
      },
      mandateName: (mandateType: BankMandateType) => {
        return bankMandateTypes[mandateType];
      },
      linkOf: (bankMandate: BankMandateFragment): RawLocation | null => {
        if (bankMandate.person) {
          return { name: 'persons', params: { id: bankMandate.person.id } };
        } else if (bankMandate.bankAccount) {
          return { name: 'companies', params: { id: bankMandate.bankAccount.companyId } };
        }

        return null;
      },
    };
  },
});
