



















































































import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Company } from '@/graphql';
import EditableFields from '@/components/Fields/EditableFields.vue';
import EditableCard from '@/components/Cards/EditableCard.vue';
import CompanySelect from '@/components/Inputs/CompanySelect.vue';
import CompanyLegalTypeSelect from '@/components/Inputs/CompanyLegalTypeSelect.vue';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import CompanyStatusSelect from '@/components/Inputs/CompanyStatusSelect.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import CompanyUsageSelect from '@/components/Inputs/CompanyUsageSelect.vue';
import CompanyLegalStatusSelect from '@/components/Inputs/CompanyLegalStatusSelect.vue';

export type OnSubmitCallback = (data: Company) => Promise<void>;

export default defineComponent({
  components: {
    CompanyLegalStatusSelect,
    CompanyUsageSelect,
    Tooltip,
    CompanyStatusSelect,
    DatePicker,
    CompanyLegalTypeSelect,
    CompanySelect,
    EditableCard,
    EditableFields,
  },
  props: {
    company: {
      type: Object as PropType<Company | null>,
      required: false,
      default: null,
    },
    onSubmit: {
      type: Function as PropType<OnSubmitCallback>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasDebitAgreement: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const model = ref<Company | null>(props.company ? { ...props.company } : null);

    watch(
      () => props.company,
      (newValue) => {
        model.value = newValue ? { ...newValue } : null;
      },
    );

    return {
      model,
      submit: async (after: Function[] = []) => {
        if (model.value) {
          await props.onSubmit(model.value);
        }
        after.forEach((func) => func());
      },
    };
  },
});
