import { ActionContext, Module } from 'vuex';
import {
  DeleteShareholderDocument,
  DeleteShareholderMutation,
  DeleteShareholderMutationVariables,
  ListShareholdersDocument,
  ListShareholdersQuery,
  ListShareholdersQueryVariables,
  ListShareholderParams,
  UpsertShareholderInput,
  UpsertShareholderMutation,
  UpsertShareholderMutationVariables,
  UpsertShareholderDocument,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type ShareholdersState = {};

type Context = ActionContext<ShareholdersState, RootState>;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async loadShareholders({ commit }: Context, listParams: ListShareholderParams = {}) {
      const { data } = await apolloClient.query<ListShareholdersQuery, ListShareholdersQueryVariables>({
        query: ListShareholdersDocument,
        variables: { listParams },
      });

      return data.listShareholders || [];
    },

    async upsertShareholder({ commit }: Context, upsertShareholderInput: UpsertShareholderInput) {
      const { data } = await apolloClient.mutate<UpsertShareholderMutation, UpsertShareholderMutationVariables>({
        mutation: UpsertShareholderDocument,
        variables: { upsertShareholderInput },
      });

      return data?.upsertShareholder?.data || null;
    },

    async deleteShareholder({ commit }: Context, shareholderId: string) {
      const { data } = await apolloClient.mutate<DeleteShareholderMutation, DeleteShareholderMutationVariables>({
        mutation: DeleteShareholderDocument,
        variables: { shareholderId },
      });

      return data?.deleteShareholder?.id || null;
    },
  },
} as Module<ShareholdersState, RootState>;
