




import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    type: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const icons: { [key: string]: string } = {
      Company: 'mdi-office-building',
      PlannedMeasure: 'mdi-home-city',
      Role: 'mdi-badge-account',
      Person: 'mdi-account',
    };

    return {
      icon: computed(() => {
        return icons[props.type] || 'mdi-cancel';
      }),
    };
  },
});
