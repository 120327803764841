






import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import { useActions } from 'vuex-composition-helpers';
import { BankMandateFragment, ListBankMandateParams } from '@/graphql';

export default defineComponent({
  props: {
    personId: {
      type: String,
      required: false,
      default: null,
    },
    bankAccountId: {
      type: String,
      required: false,
      default: null,
    },
    take: {
      type: Number,
    },
    skip: {
      type: Number,
    },
  },
  setup(props, { emit, root }) {
    const { loadBankMandates } = useActions(['loadBankMandates']);

    const bankMandates = ref<BankMandateFragment[]>([]);
    const loading = ref<boolean>(false);

    const fetchData = async () => {
      const { personId, bankAccountId, skip, take } = props;

      loading.value = true;

      bankMandates.value = await loadBankMandates({
        personId,
        bankAccountId,
        skip,
        take,
      } as ListBankMandateParams);

      loading.value = false;

      emit('loaded');
    };

    watch([() => props.personId, () => props.bankAccountId, () => props.skip, () => props.take], fetchData);

    const unsubscribeAction = root.$store.subscribeAction({
      after: (action) => {
        if (['deleteBankMandate', 'upsertBankMandate'].includes(action.type)) {
          fetchData();
        }
      },
    });

    onMounted(fetchData);
    onUnmounted(unsubscribeAction);

    return {
      bankMandates,
      loading,
    };
  },
});
