var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{scopedSlots:_vm._u([{key:"navigation-drawer",fn:function(){return [_c('RouteAware',{attrs:{"param":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var paramValue = ref.paramValue;
return [_c('PersonProvider',{attrs:{"person-id":paramValue},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var person = ref.person;
var loading = ref.loading;
return [_c('PersonDrawer',{attrs:{"open":!!paramValue,"person":person,"person-id":paramValue,"loading":loading},on:{"close":_vm.onClose}})]}}],null,true)})]}}])})]},proxy:true}])},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"no-gutters":"","justify":"start"}},[_c('h1',[_vm._v("Personen")])])],1),_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(_vm.userHasEditAccess)?_c('PersonUpsertProvider',{on:{"upserted":function($event){return _vm.onSelect($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertPerson = ref.upsertPerson;
return [_c('v-btn',{attrs:{"loading":upserting,"disabled":upserting,"color":"primary"},on:{"click":function($event){return upsertPerson({ name: 'Neue Person' })}}},[_vm._v(" Person hinzufügen ")])]}}],null,false,1938406383)}):_vm._e()],1)],1)],1),_c('v-card',[_c('PersonListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var persons = ref.persons;
var loading = ref.loading;
return [_c('v-data-table',{staticClass:"table--clickable",attrs:{"headers":[{ text: 'Name', value: 'name' }],"items":persons,"loading":loading,"search":_vm.search,"sort-desc":false,"sort-by":"name"},on:{"click:row":_vm.onSelect},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suche","prepend-inner-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_vm._v("Keine Daten")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("Die Treffer für "+_vm._s(_vm.search))]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"loading":loading,"type":"table-tbody"}})]},proxy:true}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }