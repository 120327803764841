






import { defineComponent, ref } from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';

export default defineComponent({
  props: {},
  setup(props, { emit }) {
    const { deletePerson } = useActions(['deletePerson']);

    const deleting = ref<boolean>(false);

    return {
      deleting,
      deletePerson: async (personId: string) => {
        deleting.value = true;
        await deletePerson(personId);
        deleting.value = false;

        emit('deleted');
      },
    };
  },
});
