<template>
  <v-container class="fill-height">
    <v-row class="mb-10" justify="center" no-gutters>
      <v-col cols="auto" class="text-center">
        <v-progress-circular class="mb-10" size="128" width="8" color="secondary" indeterminate></v-progress-circular>
        <p class="text-h6 text-secondary">Renewing token ...</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, onMounted } from '@vue/composition-api';
import { vuexOidcProcessSilentSignInCallback } from 'vuex-oidc';

export default defineComponent({
  setup() {
    onMounted(async () => {
      await vuexOidcProcessSilentSignInCallback();
    });
  },
});
</script>

<style scoped></style>
