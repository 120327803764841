import { CompanyUsage } from '@/graphql';

export const companyUsages: { [K in CompanyUsage]: string } = {
  [CompanyUsage.Yield]: 'SPV Bestand',
  [CompanyUsage.Financing]: 'SPV Finanzierung',
  [CompanyUsage.OperatingCompany]: 'Operative Gesellschaft',
  [CompanyUsage.Other]: 'Sonstige',
};

export default companyUsages;
