import { ActionContext, Module } from 'vuex';
import {
  DeleteBankAccountDocument,
  DeleteBankAccountMutation,
  DeleteBankAccountMutationVariables,
  ListBankAccountParams,
  ListBankAccountsDocument,
  ListBankAccountsQuery,
  ListBankAccountsQueryVariables,
  UpsertBankAccountDocument,
  UpsertBankAccountInput,
  UpsertBankAccountMutation,
  UpsertBankAccountMutationVariables,
} from '@/graphql';
import { apolloClient } from '@/apollo';
import { RootState } from '@/store/index';

export type BankAccountsState = {};

type Context = ActionContext<BankAccountsState, RootState>;

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async loadBankAccounts({ commit }: Context, listParams: ListBankAccountParams = {}) {
      const { data } = await apolloClient.query<ListBankAccountsQuery, ListBankAccountsQueryVariables>({
        query: ListBankAccountsDocument,
        variables: { listParams },
      });

      return data.listBankAccounts || [];
    },

    async upsertBankAccount({ commit }: Context, upsertBankAccountInput: UpsertBankAccountInput) {
      const { data } = await apolloClient.mutate<UpsertBankAccountMutation, UpsertBankAccountMutationVariables>({
        mutation: UpsertBankAccountDocument,
        variables: { upsertBankAccountInput },
      });

      return data?.upsertBankAccount.data || null;
    },

    async deleteBankAccount({ commit }: Context, bankAccountId: string) {
      const { data } = await apolloClient.mutate<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>({
        mutation: DeleteBankAccountDocument,
        variables: { bankAccountId },
      });

      return data?.deleteBankAccount.id || null;
    },
  },
} as Module<BankAccountsState, RootState>;
