
























































import { defineComponent, provide, reactive } from '@vue/composition-api';
import { ApolloClients } from '@vue/apollo-composable';
import { apolloClient } from '@/apollo';
import { useGetters } from 'vuex-composition-helpers';
import { RootState } from '@/store';

export default defineComponent({
  setup(_, ctx) {
    const { displayNotification, notification } = useGetters<RootState>(['displayNotification', 'notification']);
    const { oidcIsAuthenticated } = useGetters(['oidcIsAuthenticated']);
    const router = ctx.root.$router;

    const state = reactive({
      drawerOpen: false,
    });

    const logout = () => {
      router.push({ name: 'oidc-logout-callback' });
    };

    provide(ApolloClients, {
      default: apolloClient,
    });

    const links: { icon: string; path: string; title: string }[] = [
      {
        icon: 'mdi-home',
        path: '/',
        title: 'Startseite',
      },
      {
        icon: 'mdi-account',
        path: '/companies',
        title: 'Gesellschaften',
      },
      {
        icon: 'mdi-account',
        path: '/planned-measures',
        title: 'Geplante Maßnahmen',
      },
      {
        icon: 'mdi-account',
        path: '/persons',
        title: 'Personen',
      },
    ];

    return { displayNotification, notification, state, links, logout, oidcIsAuthenticated };
  },
});
