











































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';

export type OnCancelCallback = () => Promise<void>;
export type OnDeleteCallback = () => Promise<void>;

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    name: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      required: false,
    },
    secure: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    onCancel: {
      type: Function as PropType<OnCancelCallback | null>,
      required: false,
      default: null,
    },
    onDelete: {
      type: Function as PropType<OnDeleteCallback>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const open = ref<boolean>(false);

    const onCancelCallback = async () => {
      if (typeof props.onCancel === 'function') {
        await props.onCancel();
      }

      open.value = false;
    };

    const onDeleteCallback = async () => {
      await props.onDelete();
      open.value = false;
    };

    const answerValue = ref<string>('');

    return {
      onCancelCallback,
      onDeleteCallback,
      open,
      answerValue,
      disabled: computed(() => {
        return props.secure && answerValue.value !== (props.answer || props.name);
      }),
      input: (value: boolean) => {
        emit('input', value);
      },
      hide: () => {
        open.value = false;
      },
    };
  },
});
