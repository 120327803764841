var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('Toolbar',{staticClass:"sticky-header",attrs:{"dense":"","loading":_vm.loading,"title":"Bankkonten"}},[_c('BankAccountUpsertProvider',{attrs:{"company-id":_vm.companyId},on:{"upserted":_vm.created},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upsert = ref.upsert;
var upserting = ref.upserting;
return [(_vm.editable)?_c('Tooltip',{attrs:{"text":"Neues Bankkonto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-right":"-12px"},attrs:{"icon":"","loading":upserting,"disabled":upserting},on:{"click":upsert}},on),[_c('v-icon',[_vm._v("mdi-bank-plus")])],1)]}}],null,true)}):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"value":!!_vm.selected,"max-width":400,"persistent":""}},[_c('BankAccountUpsertProvider',{attrs:{"company-id":_vm.companyId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upsert = ref.upsert;
var upserting = ref.upserting;
return [(_vm.selected)?_c('v-card',{attrs:{"disabled":upserting,"loading":upserting}},[_c('v-card-title',[_vm._v(_vm._s(_vm.selected.bank))]),_c('v-card-text',[_c('BankAccountForm',{attrs:{"bank-account":_vm.selected,"disabled":upserting,"on-submit":upsert},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var submit = ref.submit;
var validInput = ref.validInput;
return [_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.unselect}},[_vm._v("Abbrechen")])],1),_c('v-col',[_c('v-btn',{attrs:{"text":"","block":"","color":"primary","loading":upserting,"disabled":!validInput},on:{"click":function($event){return submit([_vm.unselect])}}},[_vm._v(" Speichern ")])],1)],1)]}}],null,true)})],1)],1):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"value":!!_vm.selectedForMandates,"max-width":400,"persistent":""}},[_c('BankMandateUpsertProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var upserting = ref.upserting;
var upsertBankMandate = ref.upsertBankMandate;
return [(_vm.selectedForMandates)?_c('v-card',{attrs:{"disabled":upserting,"loading":upserting}},[_c('v-card-title',{domProps:{"textContent":_vm._s(("Bankbevollmächtigte" + (_vm.selectedForMandates.bank ? (" - " + (_vm.selectedForMandates.bank)) : '')))}}),_c('v-card-text',[_c('BankMandateListProvider',{attrs:{"bank-account-id":_vm.selectedForMandates.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var bankMandates = ref.bankMandates;
var loadingBankMandates = ref.loading;
return [_c('PersonListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var persons = ref.persons;
var loadingPersons = ref.loading;
return [_c('BankMandatesForm',{attrs:{"bank-mandates":bankMandates,"persons":persons,"bank-account-id":_vm.selectedForMandates.id,"disabled":_vm.loading || upserting || loadingBankMandates || loadingPersons,"loading":_vm.loading || loadingBankMandates || loadingPersons,"on-bank-mandate-add":function (person, mandateType) { return upsertBankMandate({
                      person: { id: person.id },
                      bankAccount: { id: _vm.selectedForMandates.id },
                      mandateType: mandateType,
                    }); },"editable":_vm.editable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var submit = ref.submit;
                    var selectedPerson = ref.selectedPerson;
return [_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"text":"","block":""},on:{"click":_vm.unselectForMandates}},[_vm._v(" Abbrechen ")])],1),(_vm.editable)?_c('v-col',[_c('v-btn',{attrs:{"text":"","block":"","color":"primary","disabled":!selectedPerson,"loading":upserting},on:{"click":function($event){return submit([])}}},[_vm._v(" Hinzufügen ")])],1):_vm._e()],1)]}}],null,true)})]}}],null,true)})]}}],null,true)})],1)],1):_vm._e()]}}])})],1),_c('BankAccountList',{attrs:{"items":_vm.bankAccounts,"loading":_vm.loading},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var item = ref.item;
return [_c('BankAccountDeleteProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var deleteBankAccount = ref.deleteBankAccount;
                    var deleting = ref.deleting;
return [_c('Tooltip',{attrs:{"text":"Bankmandate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":deleting},on:{"click":function($event){return _vm.selectForMandates(item)}}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)}),(_vm.editable)?_c('Tooltip',{attrs:{"text":"Bearbeiten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":deleting},on:{"click":function($event){return _vm.select(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}):_vm._e(),(_vm.editable)?_c('Tooltip',{attrs:{"text":"Löschen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":deleting},on:{"click":function($event){return deleteBankAccount(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)}):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }