var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[(_vm.loading)?[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})]:[_vm._l((_vm.items),function(document,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(document.fileName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.documentTypeProps[document.documentType]))])],1),(_vm.downloadable)?_c('DocumentDownloadProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var downloading = ref.downloading;
var downloadDocument = ref.downloadDocument;
return [_c('Tooltip',{attrs:{"text":"Herunterladen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":downloading,"icon":""},on:{"click":function($event){return downloadDocument(document.id)}}},on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}],null,true)})]}}],null,true)}):_vm._e(),(_vm.removable)?_c('DocumentDeleteProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var deleting = ref.deleting;
var deleteDocument = ref.deleteDocument;
return [_c('Tooltip',{attrs:{"text":"Löschen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":deleting,"icon":""},on:{"click":function($event){return deleteDocument(document.id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})]}}],null,true)}):_vm._e()],1)}),(!_vm.items.length)?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text--secondary"},[_vm._v("Keine Dokumente")])],1)],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }