var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"disabled":_vm.disabled},on:{"submit":function($event){$event.preventDefault();}}},[_c('EditableCard',{attrs:{"title":"Stammdaten","loading":_vm.loading,"editable":_vm.editable},scopedSlots:_vm._u([{key:"save",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-btn',{attrs:{"icon":"","loading":_vm.disabled},on:{"click":function($event){return _vm.submit([toggle])}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}},{key:"active",fn:function(){return [_c('EditableFields',{attrs:{"fields":{
          name: 'Name',
          type: 'Art der Maßnahme',
          referenceSource: 'Quelle',
          referenceId: 'Quellen ID',
          company: 'Gesellschaft',
        },"loading":_vm.disabled},scopedSlots:_vm._u([{key:"company",fn:function(){return [_c('CompanyListProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var companies = ref.companies;
        var loading = ref.loading;
return [_c('CompanySelect',{attrs:{"loading":loading,"companies":companies},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})]}}])})]},proxy:true},{key:"referenceSource",fn:function(){return [_c('PlannedMeasureSourceSelect',{model:{value:(_vm.model.referenceSource),callback:function ($$v) {_vm.$set(_vm.model, "referenceSource", $$v)},expression:"model.referenceSource"}})]},proxy:true}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"default",fn:function(){return [_vm._t("default")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }