






















import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { CompanyUsage } from '@/graphql';
import { companyUsages } from '@/constants';
import CompanyUsageChip from '@/components/Chips/CompanyUsageChip.vue';

export default defineComponent({
  components: { CompanyUsageChip },
  props: {
    value: {
      type: String as PropType<CompanyUsage | null>,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const model = ref<CompanyUsage | null>(props.value);

    watch(
      () => props.value,
      (value) => {
        model.value = value;
      },
    );

    const items = Object.keys(companyUsages) as CompanyUsage[];

    return {
      model,
      items,
      input: (companyUsage: CompanyUsage) => {
        emit('input', companyUsage);
      },
    };
  },
});
