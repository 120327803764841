<template>
  <v-container class="fill-height">
    <v-row class="mb-10" justify="center" no-gutters>
      <v-col cols="auto" class="text-center">
        <p class="text-h6 text-secondary">Erfolgreich abgemeldet.</p>
        <p class="text-secondary">
          Die Abmeldung war erfolgreich. <router-link :to="{ name: 'home' }">Zurück zur Anmeldung.</router-link>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({});
</script>

<style scoped></style>
